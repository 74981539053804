import React from 'react';
import { Button } from 'antd';

const allowedEditSettings = [
  // Platform
  'klaviyo',
  'gorgias',
  'stripe',
  // Carrier
  'shippo',
  'canadapost',
  'auspost',
  'shipstation',
  'easypost',
  'ups',
  'shopminis',
];

export const IntegrationCard = ({
  item,
  connectionStatus,
  modalVisible,
  trackingEvent,
}) => {
  return (
    <div className="Integration--card">
      <div className="Integration--card__content">
        <div>
          <div className="Integration--image">{item.logo}</div>
          <h3>{item.title}</h3>
          <div className="Integration--description">{item.description}</div>
        </div>
        {connectionStatus[item.identifier] === 'connected' ? (
          <>
            <div className="Integration--status">Active</div>
            {allowedEditSettings.includes(item.identifier) ? (
              <div className="Integration--button" style={{ marginBottom: 10 }}>
                <Button
                  type="ghost"
                  onClick={() => modalVisible(item.identifier)}
                  style={{ padding: 'var(--input-padding)' }}
                >
                  {'Edit settings'}
                </Button>
              </div>
            ) : (
              <div className="Integration--button" style={{ marginBottom: 10 }}>
                <Button
                  type="ghost"
                  href={item.oAuthUrl}
                  onClick={() => trackingEvent(item.identifier)}
                >
                  {'Edit settings'}
                </Button>
              </div>
            )}
          </>
        ) : item.oAuthUrl ? (
          <div className="Integration--button" style={{ marginBottom: 10 }}>
            <Button
              type="ghost"
              href={item.oAuthUrl}
              onClick={() => trackingEvent(item.identifier)}
            >
              {'Get started'}
            </Button>
          </div>
        ) : (
          <div className="Integration--button" style={{ marginBottom: 10 }}>
            <Button
              type="ghost"
              onClick={() => modalVisible(item.identifier)}
              style={{ padding: 'var(--input-padding)' }}
            >
              {'Get started'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
