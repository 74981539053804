import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  Form,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

class GiftReturnsStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCancel = () => {
    this.props.changeTypeReturn('picker');
    this.props.onCancel();
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <p>
            <FormattedMessage
              id="app.visitor.newReturn.giftReturnThankYouMessage"
              defaultMessage="You will receive an email with the return instructions shortly."
            />
          </p>
        </div>
        <div>
          <Button
            style={{
              width: '100%',
              backgroundColor: this.props.brandColorButton,
              borderColor: this.props.brandColorButton,
            }}
            type="primary"
            onClick={() => this.onCancel()}
          >
            <span
              style={{
                color: this.props.brandColorButtonText,
                fontStyle: this.props.brandFontStyle,
              }}
            >
              <FormattedMessage
                id="app.visitor.newReturn.closeButton"
                defaultMessage="Close"
              />
            </span>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default GiftReturnsStatus;
