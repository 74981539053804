/**
 * Component to register UPS.
 *
 * @component
 */

import React from 'react';
import { Modal, Input, message, Row, Col, Divider, Button } from 'antd';
import axios from 'axios';
// import { trackFSEvent } from '../helpers/fullstory';
const LABEL_SERVICE = 'ups';

class UPSModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      active: false,
      labelApiKey: null,
      upsUrl: this.props.upsUrl,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`/api/carrierSettings/${LABEL_SERVICE}`);
      if (response.data) {
        const { carrier } = response.data;
        this.setState({
          id: carrier.id,
          labelApiKey: carrier.labelApiKey,
          active: carrier.active,
        });
      }
    } catch (err) {
      if (err?.response?.status !== 404) {
        console.log(err);
        message.error('something went wrong please try again', 5);
      }
    }
  }

  validateFields = () => {
    const { labelApiKey } = this.state;
    if (!labelApiKey || labelApiKey === '') {
      message.error('API Key is required.');
      return false;
    }
    return true;
  };

  getPayload = () => {
    const { id, active, labelApiKey } = this.state;
    return {
      id,
      labelApiKey,
      active,
      labelService: LABEL_SERVICE,
    };
  };

  handleOk = async () => {
    this.setState({
      loading: true,
    });
    if (!this.validateFields()) {
      this.setState({
        loading: false,
      });
      return;
    }
    const payload = this.getPayload();

    try {
      const response = await axios.post('/api/carrierSettings/update', payload);
      console.log(response);
      message.success('UPS integration has been successfully set up', 5);
      this.props.setModalVisible(null);
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error('something went wrong please try again', 5);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Modal
        title="Set up your UPS integration"
        visible={true}
        width="50%"
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={() => this.props.setModalVisible(null)}
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>UPS account OAuth Link</h4>
          </Col>
          <Col span={10}>
            <Button
              style={{ width: '100%' }}
              type="primary"
              href={this.state.upsUrl}
            >
              {'Access UPS OAuth'}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Billing Account Number</h4>
            <span>
              Enter your Billing Account Number. Click on the link at the top to
              see where to find it.
            </span>
          </Col>
          <Col span={10}>
            <a
              href="https://developer.ups.com/apps"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find it here in your UPS app
            </a>
            <br />
            <Input
              name="labelApiKey"
              onChange={(e) => this.handleChange('labelApiKey', e.target.value)}
              value={this.state.labelApiKey}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default UPSModal;
