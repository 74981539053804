/**
 * Status: BETA
 *
 * Component for Refunds settings page in the Dashboard
 * that is only available to selected users.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { trackFSEvent } from '../../../../helpers/fullstory';

const FormItem = Form.Item;
const { Option } = Select;

class SettingsRefunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refundSettingsRefundShippingCosts: undefined,
      refundSettingsRefundToGiftCardLast: undefined,
      refundSettingsUseGiftCards: undefined,
      refundSettingsNotificationsCheck: undefined,
      refundSettingsGatewayCheck: undefined,
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        this.setState({ loading: false }); // Set loading to false if no data
        return;
      }

      this.setState({
        ...response.data,
        loading: false, // Set loading to false after data is set
      });
    } catch (err) {
      this.setState({ loading: false }); // Set loading to false in case of error
      message.error('Error retrieving your refund settings. Try again.', 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      const payload = {
        refundSettingsRefundToGiftCardLast:
          this.state.refundSettingsRefundToGiftCardLast,
        refundSettingsRefundShippingCosts:
          this.state.refundSettingsRefundShippingCosts,
        refundSettingsUseGiftCards: this.state.refundSettingsUseGiftCards,
        refundSettingsNotificationsCheck:
          this.state.refundSettingsNotificationsCheck,
        refundSettingsGatewayCheck: this.state.refundSettingsGatewayCheck,
      };
      trackFSEvent('Edit Refunds Settings', {
        feature: 'Refunds',
        ...payload,
      });
      try {
        const response = await axios.post(
          '/api/returnSettings/update',
          payload
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your refund settings. Please try again.',
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success('Refund settings updated successfully.', 5);
      } catch (err) {
        return message.error(
          'Error updating your refund settings. Please try again.',
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="dashboard-content"
            >
              <Col span={24}>
                <Alert
                  className="u-marginBottom--lg"
                  message="Important: multi-currency orders and label-/ restocking fees"
                  description="If you use a multi-currency checkout, we DO NOT support deducting fees at the moment for orders with currencies other than your store's default currency. If this is of interest to you, please reach out to us."
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <PageButtonHeader
                    headingTitle={'Refunds'}
                    headingRightContent={
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          Save changes
                        </Button>
                      </FormItem>
                    }
                  ></PageButtonHeader>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>Payment gateway capability</h4>
                      <span>
                        Have you checked that all the payment gateways you use
                        on Shopify support refunds? If they don't, your
                        customers won't be able to get their money back.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <h4>Payment gateway capability</h4>
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsGatewayCheck',
                              {}
                            )(
                              <Checkbox
                                checked={this.state.refundSettingsGatewayCheck}
                                onChange={(e) => this.handleChange(e)}
                              >
                                Confirmed that gateways support refunds
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row type="flex" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>Refund to gift-cards last</h4>
                      <span>
                        This setting is for orders paid with multiple payment
                        methods. If turned on, refunds will use other payment
                        methods first and only apply any leftover amount to the
                        gift-card. You can adjust this later in the dialog.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <h4>Refund to gift-cards last</h4>
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsRefundToGiftCardLast',
                              {}
                            )(
                              <Checkbox
                                checked={
                                  this.state.refundSettingsRefundToGiftCardLast
                                }
                                onChange={(e) => this.handleChange(e)}
                              >
                                Enable to use other payment methods first
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row type="flex" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>
                        Refund outbound shipping costs by default (if available)
                      </h4>
                      <span>
                        Active to refund the full initial outbound shipping
                        costs in the refund dialog. You can override this value
                        later in the dialog panel.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <h4>Refund initial outbound shipping cost</h4>
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsRefundShippingCosts',
                              {}
                            )(
                              <Checkbox
                                checked={
                                  this.state.refundSettingsRefundShippingCosts
                                }
                                onChange={(e) => this.handleChange(e)}
                              >
                                Enable to refund the full initial outbound cost
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row type="flex" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>
                        Send Shopify's refund notification email by default
                      </h4>
                      <span>
                        Refunds to the original payment method will trigger the
                        default refund notification email you have set in your
                        Shopify store. This message can be disabled later in the
                        dialog.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <h4>Send email notification for returns</h4>
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsNotificationsCheck',
                              {}
                            )(
                              <Checkbox
                                checked={
                                  this.state.refundSettingsNotificationsCheck
                                }
                                onChange={(e) => this.handleChange(e)}
                              >
                                Send return notification email
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*

                  <Row type="flex" justify="space-around" align="top">
                    <Col span={22}>
                      <Divider>Store Credit</Divider>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                    <Col span={10}>
                      <h4>Use gift-cards instead of coupon codes for Store Credit (only Shopify Plus)</h4>
                      <span>By default we create a one-time usable coupon code for the customer to use as Store Credit. If you are on Shopify Plus you can use gift-cards instead. Note: Unfortunately, Shopify does not allow creating gift-cards through their API for plans other than Shopify Plus.</span>
                    </Col>
                    <Col span={10}>
                      <FormItem>
                        {getFieldDecorator('refundSettingsUseGiftCards', {
                        })(
                        <Checkbox checked={ this.state.refundSettingsUseGiftCards } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                        */}

                  <Row type="flex" justify="end" className="u-marginBottom--md">
                    <Col>
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          Save changes
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const WrappedSettingsRefunds = Form.create()(SettingsRefunds);
export default WrappedSettingsRefunds;
