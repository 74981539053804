/**
 * Status: BETA
 *
 * Component for Inventory Management'} settings page in the Dashboard
 * that is only available to selected users.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Table,
  Tag,
  Popconfirm,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import InventoryStock from './components/InventoryStock';

const FormItem = Form.Item;
const { Option } = Select;

class InventoryManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refundSettingsRestock: undefined,
      refundSettingsRestockLocation: undefined,
      refundSettingsRestockLocationId: undefined,
      refundSettingsRestockLocationName: undefined,
      locations: [],
      restockLocation: undefined,
      restockLocationName: undefined,
      loadingIndicatorLocations: false,

      inventoryStock: false,
      inventoryStockModal: false,
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      const response2 = await axios.get('/api/refunds/locations');
      if (response.data == null) {
        this.setState({ loading: false }); // Set loading to false if no data
        return;
      }

      this.setState({
        ...response.data,
        ...response2.data,
        loading: false, // Set loading to false after data is set
      });
    } catch (err) {
      this.setState({ loading: false }); // Set loading to false in case of error
      message.error('Error retrieving your refund settings. Try again.', 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      const payload = {
        refundSettingsRestockLocationId: this.state.restockLocation || null,
        refundSettingsRestockLocationName: this.state.restockLocationName,
        refundSettingsRestock: this.state.refundSettingsRestock,
        refundSettingsRestockLocation: this.state.refundSettingsRestockLocation,
      };
      trackFSEvent('Edit Inventory Management Settings', {
        feature: 'Inventory Management',
        ...payload,
      });
      try {
        const response = await axios.post(
          '/api/returnSettings/update',
          payload
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your refund settings. Please try again.',
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success('Refund settings updated successfully.', 5);
      } catch (err) {
        return message.error(
          'Error updating your refund settings. Please try again.',
          5
        );
      }
    });
  };

  loadRestockLocations = async (e) => {
    const enableRestockLocation = e.target.checked;
    const target = e.target.id;

    if (!enableRestockLocation) {
      return this.setState({
        restockLocation: null,
        restockLocationName: null,
        [target]: enableRestockLocation,
      });
    }

    this.setState({
      loadingIndicatorLocations: true,
    });

    try {
      const response = await axios.get('/api/refunds/locations');

      if (response.data.status === 'error') {
        this.setState({
          loadingIndicatorLocations: false,
        });

        return message.error('Error finding locations. Please try again.', 5);
      }

      const { locations } = response.data;
      let restockLocation = undefined;
      if (this.state.refundSettingsRestockLocationId) {
        restockLocation = parseInt(this.state.refundSettingsRestockLocationId);
      } else if (locations != null && locations.length === 1) {
        restockLocation = locations[0].id;
      }

      return this.setState({
        ...response.data,
        restockLocation,
        [target]: enableRestockLocation,
        loadingIndicatorLocations: false,
      });
    } catch (err) {
      message.error('Error finding locations. Please try again.', 5);
      this.setState({
        loadingIndicatorLocations: false,
      });
    }
  };

  updateLocation = (locationId) => {
    const locations = this.state.locations;
    let locationName = '';

    locations.map((location) => {
      if (location.id === locationId) {
        locationName = location.name;
      }
    });

    this.setState({
      restockLocation: locationId,
      restockLocationName: locationName,
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    const restockDisabled =
      target === 'refundSettingsRestock' && e.target.checked === false;

    if (restockDisabled) {
      this.setState({
        refundSettingsRestockLocation: false,
        restockLocation: null,
        restockLocationName: null,
        [target]: e.target.checked,
      });
    } else {
      this.setState({
        [target]: e.target.checked,
      });
    }
  };

  inventoryStockModalOpen = () => {
    this.setState({
      inventoryStockModal: true,
    });
  };

  inventoryStockModalClose = (oldReturnSettings) => {
    this.setState({
      ...oldReturnSettings,
      inventoryStockModal: false,
      indexOfField: undefined,
    });
  };

  updateState = (payload) => {
    this.setState({
      ...payload,
    });
  };

  handleReserveEdit = (index) => {
    this.setState({
      indexOfField: index,
      inventoryStockModal: true,
    });
  };

  deleteReserverField = async (field) => {
    let fields = this.state.reserveStockFields;
    let productList = this.state.productTagList;
    if (field?.variantId) {
      fields = fields.filter((value) => value.variantId !== field.variantId);
    } else {
      fields = fields.filter(
        (value) =>
          value.type !== 'Tag' ||
          !value.title.every((tag) => field.title.includes(tag))
      );
      productList = productList.filter((tag) => !field.title.includes(tag));
    }
    const payload = {
      reserveStockFields: fields,
      productTagList: productList,
    };
    try {
      const response = await axios.post('/api/returnSettings/update', payload);
      if (response.data.status === 'error') {
        return message.error(
          'Error updating your exchange settings. Please try again.',
          5
        );
      }
      this.setState({
        reserveStockFields: fields,
        productTagList: productList,
      });
      return message.success('Successfully deleted.', 5);
    } catch (err) {
      return message.error(
        `Error deleting the ${field.type === 'tag' ? 'tag' : 'product'
        }. Please try again.`,
        5
      );
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const oldReturnSettings = {
      productTagList: this.state.productTagList,
      reserveStockFields: this.state.reserveStockFields,
    };
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    const locationOptions = this.state.locations.map((location) => {
      return (
        <Option key={location.id} value={location.id.toString()}>
          {location.name}
        </Option>
      );
    });

    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Tags/Products',
        dataIndex: 'title',
        render: (title, payload) => {
          if (payload.type === 'Product') {
            return (
              <Tag
                color="gold"
                key={
                  payload.variantTitle
                    ? `${title}(${payload.variantTitle})`
                    : title
                }
                style={{ marginBottom: 3 }}
              >
                {payload.variantTitle
                  ? `${title}(${payload.variantTitle})`
                  : title}
              </Tag>
            );
          } else {
            return (
              <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
              >
                {title.map((value) => {
                  return (
                    <Tag color="gold" key={value} style={{ marginBottom: 10 }}>
                      {value}
                    </Tag>
                  );
                })}
              </div>
            );
          }
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record) => {
          if (record.variantId) {
            return `edit-${record.variantId}`;
          }
          return `edit-${text}`;
        },
        render: (text, record, index) => {
          return (
            <Button size="small" onClick={(e) => this.handleReserveEdit(index)}>
              Edit
            </Button>
          );
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          if (record.variantId) {
            return `delete-${record.variantId}`;
          }
          return `delete-${text}`;
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete.?"
              onConfirm={() => this.deleteReserverField(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        {
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="dashboard-content"
            >
              <Col span={24}>
                <Alert
                  className="u-marginBottom--lg"
                  message="Important: Your restocking options have been moved here."
                  description={
                    <React.Fragment>
                      <span>
                        You can edit your settings here to achieve your desired restocking configuration. 
                        You can also choose a different default restock location, or reserve inventory with our{' '}
                        <a 
                          href="https://support.richreturns.io/en/articles/10211416-how-can-i-reserve-inventory-in-rich-returns"
                          target="_blank"
                        >
                          Out-of-Stock
                        </a>{' '}
                        feature.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col>
                <Form onSubmit={this.handleSubmit}>
                  <PageButtonHeader
                    headingTitle={'Inventory Management'}
                    headingRightContent={
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          Save changes
                        </Button>
                      </FormItem>
                    }
                  ></PageButtonHeader>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>Restock items by default</h4>
                      <span>
                        Enabling this will have your checkbox checked by default in your restocking dialogs. 
                        You can always uncheck the box manually later in the dialog.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsRestock',
                              {}
                            )(
                              <Checkbox
                                checked={this.state.refundSettingsRestock}
                                onChange={(e) => this.handleChange(e)}
                              >
                                Restock items by default at specified location.
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row type="flex" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%', paddingBottom: 20 }}
                    >
                      <h4>Set a default restock location</h4>
                      <span>
                        By default, we'll use the stock location from the original order. 
                        You can choose a different default location for restocking items here, 
                        and you can change it later in the dialog if needed.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem>
                            {getFieldDecorator(
                              'refundSettingsRestockLocation',
                              {}
                            )(
                              <Checkbox
                                checked={
                                  this.state.refundSettingsRestockLocation
                                }
                                onChange={(e) => this.loadRestockLocations(e)}
                                disabled={
                                  !this.state.refundSettingsRestock ||
                                  this.state.loadingIndicatorLocations
                                }
                              >
                                Enable to change your default restocking location.
                              </Checkbox>
                            )}
                          </FormItem>
                          {this.state.loadingIndicatorLocations ? (
                            <div className="GlobalLoader GlobalLoader--xs u-marginTop--sm"></div>
                          ) : null}
                          {this.state.refundSettingsRestockLocation &&
                            this.state.locations != null &&
                            this.state.locations.length > 0 ? (
                            <FormItem>
                              <Select
                                defaultValue={
                                  this.state.refundSettingsRestockLocationId ||
                                  undefined
                                }
                                style={{ width: '100%' }}
                                onChange={this.updateLocation.bind(this)}
                                placeholder="Select restock location"
                                className="u-marginTop--sm"
                              >
                                {locationOptions}
                              </Select>
                            </FormItem>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Row type="flex" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row> */}

                  {/* <div
                    className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                    style={{ gap: 20, width: '100%' }}
                  >
                    <div
                      className="flex-col-md-3 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <h4>Out of Stock</h4>
                      <span>
                        Mark an item from exchange menu as 'out of stock', if
                        it's quantity is less than the quantity of current item
                        in the return.
                      </span>
                    </div>
                    <div
                      className="flex-col-md-8 flex-col-sm-12"
                      style={{ padding: 0, width: '100%' }}
                    >
                      <div className="ContentPanel">
                        <div
                          className="flex-row flex-row--noMargin"
                          style={{ width: '100%', gap: 10 }}
                        >
                          <FormItem style={{ marginBottom: 'unset' }}>
                            {getFieldDecorator(
                              'inventoryStock',
                              {}
                            )(
                              <Checkbox
                                checked={this.state.inventoryStock}
                                onChange={(e) => this.handleChange(e)}
                              >
                                Enable
                              </Checkbox>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {(
                    <div className="ContentPanel">
                      <div className="u-marginBottom--sm">
                        <h4>Out of Stock</h4>
                        <span>
                          Mark an item as out of stock if it's quantity is less than a specific amount. 
                          Usually used if you need to reserve inventory for specific cases or workflows.
                        </span>
                        <br/>
                      </div>
                      <div className="u-marginBottom--md">
                        <Button
                          type="ghost"
                          style={{ width: '75px' }}
                          onClick={this.inventoryStockModalOpen}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ maxWidth: "1050px" }}>
                        {this.state.reserveStockFields?.length > 0 && (
                          <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: 768 }}>
                              <Table
                                rowKey={(_, index) => index}
                                columns={columns}
                                dataSource={this.state.reserveStockFields}
                                locale={{
                                  emptyText: (
                                    <div className="flex-row flex-middle-xxs flex-center-xxs">
                                      <div className="flex-col-xxs-12">
                                        <IconTray size="md" />
                                      </div>
                                      <div>
                                        <p className="TextBody TextBody--xxxs">
                                          No Data
                                        </p>
                                      </div>
                                    </div>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {this.state.inventoryStockModal && (
                          <InventoryStock
                            visible={this.state.inventoryStockModal}
                            onCancel={this.inventoryStockModalClose}
                            productTagNames={this.state.productTagNames}
                            productTagList={this.state.productTagList}
                            reserveStockFields={this.state.reserveStockFields}
                            updateState={this.updateState}
                            position={this.state.indexOfField}
                            oldReturnSettings={oldReturnSettings}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </React.Fragment>
        }
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="dashboard-content"
          >
            <Col span={24}>
              <Row type="flex" justify="end">
                <Button
                  type="primary"
                  onClick={this.handleSubmit}
                >
                  Save changes
                </Button>
              </Row>
            </Col>
          </Row>
      </React.Fragment>
    );
  }
}

const WrappedSettingsRefunds = Form.create()(InventoryManagement);
export default WrappedSettingsRefunds;
