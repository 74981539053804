import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  Form,
  Tabs,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import GiftReturnWithoutOrderInfo from './giftReturnsWithoutOrderInfo';
import GiftReturnWithOrderInfo from './giftReturnsWithOrderInfo';
import GiftReturnsPicker from './giftReturnPick';
import GiftReturnsStatus from './giftReturnStatus';

const { TabPane } = Tabs;

class GiftReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      buttonLoading: false,
      orderSearchError: undefined,

      typeReturn: 'picker', // picker, giftReturnWithOrderInfo, giftReturnWithoutOrderInfo, status
    };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.props.onOk();
  };

  handleSave = () => {
    // this.props.onSave();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.handleSubmit(values);
      }
    });
  };

  componentWillUnmount() {
    this.setState({
      typeReturn: 'picker',
    });
  }

  async handleSubmit(values) {
    try {
      this.setState({
        buttonLoading: true,
        orderSearchError: undefined,
      });

      const companyIdentifier = this.props.companyIdentifier;
      let {
        orderNumber,
        orderZipCode,
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      } = values;
      orderNumber = orderNumber.trim();
      orderZipCode = orderZipCode.trim();
      const response = await axios.post('/api/visitors/return/products', {
        orderNumber,
        orderEmail: orderZipCode,
        companyIdentifier,
        typeReturn: 'giftReturn',
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      });
      if (response?.data?.status === 'error') {
        const { intl } = this.props;
        const errorMessage = response.data.error;
        const productDeleted = response.data.productDeleted;
        let translationId;
        let translationDescription;
        let translationDefaultMessage;
        if (errorMessage === 'orderNotFound') {
          translationId = 'app.visitor.newReturn.errorOrderNotFound';
          translationDescription = 'Error when order is not found in Shopify.';
          translationDefaultMessage = 'Cannot find your order.';
        } else if (errorMessage === 'returnExists') {
          translationId = 'app.visitor.newReturn.errorReturnExists';
          translationDescription =
            'Error when there is an existing return in the system.';
          translationDefaultMessage = 'Return already exists.';
        } else if (errorMessage === 'returnTooLate') {
          translationId = 'app.visitor.newReturn.errorReturnTooLate';
          translationDescription = 'Error when outside of allowed timeframe.';
          translationDefaultMessage =
            'Sorry this return is outside of our allowed timeframe for returning products.';
        } else if (errorMessage === 'returnExcluded') {
          translationId = 'app.visitor.newReturn.errorReturnExcluded';
          translationDescription =
            'Error when excluded from being returned by merchant.';
          translationDefaultMessage =
            "Sorry we're unable to offer you a return as your item(s) were on sale.";

          // new
        } else if (errorMessage === 'returnUnfulfilled') {
          translationId = 'app.visitor.newReturn.errorReturnUnfulfilled';
          translationDescription =
            'Error when excluded because unfulfilled order.';
          translationDefaultMessage =
            'It looks like your order has not shipped, yet. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'returnUnpaid') {
          translationId = 'app.visitor.newReturn.errorReturnUnpaid';
          translationDescription = 'Error when excluded because unpaid order.';
          translationDefaultMessage =
            'It looks like your order has not been paid, yet, or has already been refunded. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'returnCancelled') {
          translationId = 'app.visitor.newReturn.errorReturnCancelled';
          translationDescription =
            'Error when excluded because cancelled order.';
          translationDefaultMessage =
            'It looks like your order has been cancelled. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'orderInvalid' && productDeleted) {
          translationId = 'app.visitor.newReturn.errorProductDeleted';
          translationDescription =
            'Error when excluded because e.g. order contains deleted products.';
          translationDefaultMessage = `All of the products in your order have been removed from the store and cannot be returned through the portal. Contact the store’s support email for more details.`;
        } else if (errorMessage === 'orderInvalid') {
          translationId = 'app.visitor.newReturn.errorOrderInvalid';
          translationDescription =
            'Error when excluded because e.g. order contains deleted products.';
          translationDefaultMessage =
            'It looks like there was an issue requesting details for your order. Please contact customer support.';
        }
        message.error(
          intl.formatMessage({
            id: translationId,
            description: translationDescription,
            defaultMessage: '',
          }),
          15
        );
        return this.setState({
          translationId,
          translationDescription,
          translationDefaultMessage: '',
          orderSearchError: errorMessage,
          buttonLoading: false,
        });
      }
      this.props.updateBrandSettings({
        ...response.data,
      });
      this.setState({
        orderSearchError: undefined,
      });
      this.props.onCancel();
      return this.props.history.push('/return/order');
    } catch (err) {
      console.error(err);
      message.error(
        'Unable to search your order at the moment, Please confirm products are available in store.',
        4
      );
      this.setState({
        buttonLoading: false,
      });
    }
  }

  changeTypeReturn = (typeReturn) => {
    this.setState({
      typeReturn,
    });
  };

  onCancel = () => {
    this.setState({
      visible: false,
      typeReturn: 'picker',
    });
    this.props.onCancel();
  };

  handleBack = () => {
    if (this.state.typeReturn === 'picker') {
      this.onCancel();
    } else {
      this.changeTypeReturn('picker');
      document.activeElement.blur();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl;
    const title = (
      <div>
        <Button
          type="custom"
          onClick={() => this.handleBack()}
          style={{ marginRight: '10px' }}
        >
          <Icon type="left" />
          <FormattedMessage
            id="app.visitor.newReturn.back"
            description="Back button"
            defaultMessage="Back"
          />
        </Button>
        <Icon type="gift" style={{ paddingRight: '10px' }} />
        <FormattedMessage
          id="app.visitor.newReturn.returnYourGift"
          defaultMessage="Return Your Gift"
        />
      </div>
    );

    const congratulationTitle = (
      <div>
        <Button
          type="custom"
          onClick={() => this.handleBack()}
          style={{ marginRight: '10px' }}
        >
          <Icon type="left" />
          <FormattedMessage
            id="app.visitor.order.buttonGoBack"
            description="Back button"
            defaultMessage="Back"
          />
        </Button>
        🥳{' '}<FormattedMessage
          id="app.visitor.newReturn.returnGiftSuccess"
          defaultMessage="Your gift return request has been received!"
        />
      </div>
    );
    return (
      <React.Fragment>
        <Modal
          width={this.props.featureMultilangReturnReasons ? 1100 : 720}
          title={
            this.state.typeReturn === 'status' ? congratulationTitle : title
          }
          visible={this.props.visible}
          onOk={this.state.handleOk}
          onCancel={this.onCancel}
          footer={null}
        >
          {this.state.typeReturn === 'picker' && (
            <GiftReturnsPicker
              brandColorButtonText={this.props.brandColorButtonText}
              brandColorButton={this.props.brandColorButton}
              brandFontStyle={this.props.brandFontStyle}
              brandFontSize={this.props.brandFontSize}
              brandColorText={this.props.brandColorText}
              changeTypeReturn={this.changeTypeReturn}
            />
          )}
          {this.state.typeReturn === 'giftReturnWithOrderInfo' && (
            <GiftReturnWithOrderInfo
              visible={this.state.displayGiftReturns}
              onCancel={this.props.onCancel}
              companyIdentifier={this.props.companyIdentifier}
              updateBrandSettings={this.props.updateBrandSettings}
              history={this.props.history}
              intl={this.props.intl}
              brandColorButtonText={this.props.brandColorButtonText}
              brandColorButton={this.props.brandColorButton}
              brandFontStyle={this.props.brandFontStyle}
              brandFontSize={this.props.brandFontSize}
              brandColorText={this.props.brandColorText}
            />
          )}
          {this.state.typeReturn === 'giftReturnWithoutOrderInfo' && (
            <GiftReturnWithoutOrderInfo
              visible={this.state.displayGiftReturns}
              onCancel={this.props.onCancel}
              companyIdentifier={this.props.companyIdentifier}
              updateBrandSettings={this.props.updateBrandSettings}
              history={this.props.history}
              intl={this.props.intl}
              brandColorButtonText={this.props.brandColorButtonText}
              brandColorButton={this.props.brandColorButton}
              brandFontStyle={this.props.brandFontStyle}
              brandFontSize={this.props.brandFontSize}
              brandColorText={this.props.brandColorText}
              changeTypeReturn={this.changeTypeReturn}
            />
          )}
          {this.state.typeReturn === 'status' && (
            <GiftReturnsStatus
              onCancel={this.props.onCancel}
              brandColorButtonText={this.props.brandColorButtonText}
              brandColorButton={this.props.brandColorButton}
              brandFontStyle={this.props.brandFontStyle}
              brandFontSize={this.props.brandFontSize}
              brandColorText={this.props.brandColorText}
              changeTypeReturn={this.changeTypeReturn}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

const WrappedGiftReturns = Form.create()(GiftReturns);

export default WrappedGiftReturns;
