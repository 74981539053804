/**
 * Component for Custom domain settings page in the Dashboard
 * that is part of the Brand Settings.
 *
 * @component
 */

import { 
  Alert, 
  message, 
  Form, 
  Button, 
  Input, 
  Divider, 
  Row, 
  Col,
  Icon,
} from 'antd';
import axios from 'axios';
import React from 'react';

import { Link } from 'react-router-dom';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const FormItem = Form.Item;
class CustomDomain extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      customDomain: null,
      loading: true,
      identifier: null,
      loader: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/brandSettings');
      if (response.data.status === 'error') {
        this.setState({ loading: false });
        return message.error(
          'Error retrieving your brand settings. Try again.',
          5
        );
      }
      if (response.data == null) {
        this.setState({ loading: false });
        return;
      }
      return this.setState({
        loading: false,
        customDomain: response.data.customDomain,
        identifier: response.data.identifier
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error(
        'Error retrieving your custom domain settings. Try again.',
        5
      );
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({ loader: true })
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post('/api/brandSettings/updateCustomDomain', {
          ...values,
        });
        if (response.data.status === 'error') {
          this.setState({ loader: false })
          return message.error(
            'Error updating your domain settings. Please try again.',
            5
          );
        } else if (response.data.code === 81053) {
          this.setState({ loader: false })
          return message.error('Domain Already Exist.', 5);
        }
        this.setState({ loader: false })
        return message.success('Domain settings successfully updated.', 5);
      } catch (err) {
        this.setState({ loader: false })
        return message.error(
          'Error updating your domain settings. Please try again.',
          5
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.context.featureCustomDomain === undefined || this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Own domain'}
           headingRightContent={
            <FormItem>
              <Button type="primary" 
                onClick={this.handleSubmitForm}
                loading={this.state.loader}>
                Save changes
              </Button>
            </FormItem>
          }
        
        ></PageButtonHeader>
        {this.context.featureCustomDomain ? (
          <React.Fragment>
            <Alert
              showIcon
              type="info"
              message="Looking to start using a custom domain?"
              description={
                <React.Fragment>
                  <p className="u-marginTop--sm u-marginBottom--xs">
                    Please provide your custom domain so we can set it up for your account.
                  </p>
                </React.Fragment>
              }
            />

            <Row type="flex" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmitForm} className="removeFormMargin">
              <div
                className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                style={{ gap: 20, width: '100%' }}
              >
                <div
                  className="flex-col-md-3 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <h4>Insert your custom domain</h4>
                  <span>
                    Enter your custom domain to create the custom app URL.
                  </span>
                </div>
                <div
                  className="flex-col-md-8 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <div className="ContentPanel">
                    <h4>Insert custom domain</h4>
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ width: '100%', gap: 10 }}
                    >
                      <FormItem>
                        {getFieldDecorator('customDomain', {
                          initialValue: this.state.customDomain,
                          rules: [
                            {
                              required: true,
                              message: 'Please add the custom domain',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Custom Domain"
                            onChange={this.handleInputChange}
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>
                </div>
              </div>

              <Row type="flex" align="top">
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>

              <div
                className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                style={{ gap: 20, width: '100%' }}
              >
                <div
                  className="flex-col-md-3 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <h4>Generate CNAME</h4>
                  <span>
                    Click the button to copy CNAME. Configure your domain to point to this provided CNAME.
                  </span>
                </div>
                <div
                  className="flex-col-md-8 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <div className="ContentPanel">
                    <h4>Generate CNAME</h4>
                    <div
                      className="flex-row flex-row--noMargin"
                      style={{ width: '100%', gap: 10 }}
                    >
                      <CopyToClipboard text={`customers.richcommerce.co`}>
                        <Button
                          type="ghost"
                          onClick={() =>
                            message.success(
                              'CNAME copied successfully',
                              3
                            )
                          }
                          style={{ padding: 'var(--input-padding)' }}
                        >
                          <Icon type="copy" />
                          Copy CNAME
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--lg"
                style={{ marginTop: 30 }}
              >
                <FormItem>
                  <Button type="primary" htmlType="submit" loading={this.state.loader}>
                    Save changes
                  </Button>
                </FormItem>
              </div>
            </Form>
          </React.Fragment>
        ) : (
          <div className="ContentPanel">
            <div className="flex-row flex-between-xxs u-rowGap--sm flex-middle-xxs">
              <div className="flex-col-xxs-12 flex-col-md-5">
                <Upgrade
                  title={'Custom domain for returns portal'}
                  description={
                    <>
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: 'rgba(0, 0, 0, 0.65)',
                        }}
                      >
                        This feature will allow you to host your returns portal
                        on your own domain. For example{' '}
                        <strong>returns.brand.com, exchanges.brand.com</strong>,
                        or any other term.
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: 'rgba(0, 0, 0, 0.65)',
                        }}
                      >
                        If you would like the customer returns portal to be own
                        your own domain, please contact our team.
                      </p>
                    </>
                  }
                />
              </div>
              <div className="flex-col-md-7 flex-col-xxs-12">
                <img
                  src="http://assets.reviews.io/img/richreturns/dashboard/custom-domain/rr-custom-domain-banner.png"
                  style={{ width: '100%', borderRadius: 8 }}
                ></img>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const WrappedCustomDomain = Form.create()(CustomDomain);
export default WrappedCustomDomain;
