/**
 * Component for Brand settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Tag,
  Upload,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { TwitterPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { getAppInfo } from '../../../../helpers/appInfo';
import { trackFSEvent } from '../../../../helpers/fullstory';

const FormItem = Form.Item;
const Search = Input.Search;
const { Option } = Select;

class Brand extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      brandName: '',
      identifier: '',
      storeUrl: '',
      storeShopifyUrl: '',
      faviconUrl: '',
      logoUrl: '',
      backgroundImageUrl: '',
      brandColor: '#F9FBFF',
      brandColorButton: '#67C2C2',
      brandColorButtonText: '#ffffff',
      brandColorText: '#000000',
      brandIncentivesColor: '#67C2C2',
      contactEmail: '',
      contactUrl: '',
      enableContactUrl: undefined,
      removeBackToStoreHeader: undefined,
      poweredByButtonEnabled: undefined,
      brandFontStyle: undefined,
      brandFontSize: undefined,
      appUrl: undefined,
      trackingChange: {
        uploadPhoto: false,
        changeEventInfo: false,
      },
      loading: true,
      isShopminisExtensionEnabled: false,
      shopminisExtensionButtonLabel: "Start a return",
      shopminisReturnInProgressLabel: "Return Requested",
    };
  }

  componentDidMount = async () => {
    this.initializeComponent();
  };

  initializeComponent = async () => {
    try {
      await this.fetchBrandSettings();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error loading brand settings. Please try again.', 4);
    }
  };

  fetchBrandSettings = async () => {
    try {
      const response = await axios.get('/api/brandSettings');
      if (response.data.status === 'error') {
        return message.error(
          'Error retrieving your brand settings. Try again.',
          5
        );
      }
      if (response.data == null) {
        return;
      }

      const info = await getAppInfo();
      this.setState({ appUrl: info.appUrl });

      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error('Error retrieving your brand settings. Try again.', 5);
    }

    // eslint-disable-next-line
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error('Error! Please Check again');
      }
    });
  };

  hasChangeInfo = (values) => {
    return (
      values.brandName !== this.state.brandName ||
      values.storeUrl !== this.state.storeUrl ||
      values.storeShopifyUrl !== this.state.storeShopifyUrl ||
      values.contactEmail !== this.state.contactEmail
    );
  };

  addPrefixColor = (hexColor) => {
    if (typeof hexColor !== 'string' || !hexColor) {
      return;
    }

    if (!hexColor.startsWith('#')) {
      return `#${hexColor}`;
    }

    return hexColor;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      values.poweredByButtonEnabled = this.state.poweredByButtonEnabled;
      values.brandColor = this.addPrefixColor(values.brandColor);
      values.brandColorButton = this.addPrefixColor(values.brandColorButton);
      values.brandColorButtonText = this.addPrefixColor(
        values.brandColorButtonText
      );
      values.brandColorText = this.addPrefixColor(values.brandColorText);
      values.brandIncentivesColor = this.addPrefixColor(
        values.brandIncentivesColor
      );
      values.isShopminisExtensionEnabled = this.state.isShopminisExtensionEnabled;

      if (err) {
        return message.error(
          'Error with the data you entered. Please check again.',
          5
        );
      }
      values.brandFontSize = parseInt(values.brandFontSize);
      if (isNaN(values.brandFontSize)) {
        return message.error(
          'Brand font size is not valid. Please check again.',
          5
        );
      }
      trackFSEvent('Edit Brand Settings', {
        feature: 'Brand Settings',
        ...values,
      });
      try {
        const response = await axios.post('/api/brandSettings/update', values);
        if (response.data.status === 'error') {
          return message.error(
            'Error with the data you entered. Please check again.',
            5
          );
        }
        message.success('Settings saved successfully.', 4);
        return this.setState({
          ...response.data,
        });
      } catch (err) {
        return message.error('Error saving the data. Please try again.', 5);
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  async checkFavicon(file) {
    const allowedFileTypes = [
      'image/x-icon',
      'image/png',
      'image/jpeg',
      'image/svg+xml',
    ];
    if (!allowedFileTypes.includes(file.type)) {
      message.error(
        'Only .ico, .jpeg, .png and .svg files smaller than 16 KB allowed.',
        5
      );
      return false;
    }
    if (file.size / 1024 > 16) {
      message.error('Only files smaller than 16 KB allowed.', 5);
      return false;
    }
    return true;
  }

  async checkLogo(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
    if (!allowedFileTypes.includes(file.type)) {
      message.error(
        'Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.',
        5
      );
      return false;
    }
    if (file.size / 1024 / 1024 > 1) {
      message.error('Only files smaller than 1 MB allowed.', 5);
      return false;
    }
    return true;
  }

  async checkBackground(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg'];
    if (!allowedFileTypes.includes(file.type)) {
      message.error('Only .jpg and .jpeg, files smaller than 2 MB allowed.', 5);
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      message.error('Only files smaller than 2 MB allowed.', 5);
      return false;
    }
    return true;
  }

  async handleFaviconUpload(options) {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    let fileName = '';
    try {
      let response = await axios.post('/api/brandSettings/favicon/signUpload', {
        fileType: options.file.type,
        fileSize: options.file.size / 1024,
      });
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 16 KB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .ico, .jpeg, .png and .svg files smaller than 16 KB allowed.',
          5
        );
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch (err) {
      return message.error('Error preparing upload. Try again.', 4);
    }

    try {
      // eslint-disable-next-line no-unused-vars
      let fileUpload = await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post('/api/brandSettings/favicon/update', {
        faviconUrl: bucketUrl,
      });
      if (saveUrl.data.error) {
        return message.error('Error saving favicon. Try again.', 4);
      }
      message.success('Favicon uploaded successfully.', 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving favicon. Try again.', 4);
    }
  }

  async handleLogoUpload(options) {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    let fileName = '';
    try {
      let response = await axios.post('/api/brandSettings/logo/signUpload', {
        fileType: options.file.type,
        fileSize: options.file.size / 1024 / 1024,
      });
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 1 MB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.',
          5
        );
      }
      this.setState({
        trackingChange: { ...this.state.trackingChange, uploadPhoto: true },
      });
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch (err) {
      return message.error('Error preparing upload. Try again.', 4);
    }

    try {
      // eslint-disable-next-line no-unused-vars
      let fileUpload = await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post('/api/brandSettings/logo/update', {
        logoUrl: bucketUrl,
      });
      if (saveUrl.data.error) {
        return message.error('Error saving logo. Try again.', 4);
      }
      message.success('Logo uploaded successfully.', 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving logo. Try again.', 4);
    }
  }

  async handleBackgroundUpload(options) {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    let fileName = '';
    try {
      let response = await axios.post(
        '/api/brandSettings/background/signUpload',
        {
          fileType: options.file.type,
          fileSize: options.file.size / 1024 / 1024,
        }
      );
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 2 MB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .jpg, .jpeg and .png files smaller than 2 MB allowed.',
          5
        );
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch (err) {
      return message.error('Error preparing upload. Try again.', 4);
    }

    try {
      // eslint-disable-next-line no-unused-vars
      let fileUpload = await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post('/api/brandSettings/background/update', {
        backgroundImageUrl: bucketUrl,
      });
      if (saveUrl.data.error) {
        return message.error('Error saving background image. Try again.', 4);
      }
      message.success('Background image uploaded successfully.', 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving background image. Try again.', 4);
    }
  }

  async handleDeleteLogo() {
    try {
      const response = await axios.post('/api/brandSettings/logo/delete', {});
      if (response.data.error) {
        return message.error('Error deleting current logo. Try again.', 4);
      }
      message.success('Current logo deleted successfully', 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error('Error deleting current logo. Try again.', 4);
    }
  }

  async handleDeleteFavicon() {
    try {
      const response = await axios.post(
        '/api/brandSettings/favicon/delete',
        {}
      );
      if (response.data.error) {
        return message.error('Error deleting current favicon. Try again.', 4);
      }
      message.success('Current favicon deleted successfully', 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error('Error deleting current favicon. Try again.', 4);
    }
  }

  async handleDeleteBackground() {
    try {
      const response = await axios.post(
        '/api/brandSettings/background/delete',
        {}
      );
      if (response.data.error) {
        return message.error(
          'Error deleting current background image. Try again.',
          4
        );
      }
      message.success('Current background image deleted successfully', 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error('Error deleting current background image. Try again.', 4);
    }
  }

  handleColorChange(color) {
    const brandColor = color.hex;
    this.props.form.setFieldsValue({
      [`brandColor`]: brandColor,
    });
    return this.setState({
      brandColor,
    });
  }

  handleColorChangeButton(color) {
    let brandColorButton = color.hex;
    this.props.form.setFieldsValue({
      brandColorButton: brandColorButton,
    });
    return this.setState({
      brandColorButton,
    });
  }

  handleColorChangeButtonText(color) {
    let brandColorButtonText = color.hex;
    this.props.form.setFieldsValue({
      [`brandColorButtonText`]: brandColorButtonText,
    });
    return this.setState({
      brandColorButtonText,
    });
  }

  handleColorChangeText(color) {
    const brandColorText = color.hex;
    this.props.form.setFieldsValue({
      [`brandColorText`]: brandColorText,
    });
    return this.setState({
      brandColorText,
    });
  }

  handleColorChangeBadgeText(color) {
    const brandIncentivesColor = color.hex;
    this.props.form.setFieldsValue({
      [`brandIncentivesColor`]: brandIncentivesColor,
    });
    return this.setState({
      brandIncentivesColor,
    });
  }

  async handleIdentifierChange(identifier) {
    const response = await axios.post('/api/brandSettings/checkIdentifier', {
      identifier,
    });
    if (response.data.status === 'error') {
      const errorMessage = response.data.error;
      return message.error(errorMessage, 4);
    }
    message.success('Identifier changed successfully', 4);
    return this.setState({
      ...response.data,
      trackingChange: {
        ...this.state.trackingChange,
        changeEventInfo: true,
      },
    });
  }

  handleFontSizeChange = (value) => {
    const pattern = /^(1[4-9]|2[0-6]|30)$/;
    if (pattern.test(value)) {
      this.setState({
        brandFontSize: value,
      });
    }
  };

  isColorLight(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
  }

  render() {
    const subscription = this.context;
    const { getFieldDecorator } = this.props.form;
    const portalLink =
      this.state.appUrl + `/return/new?c=${this.state.identifier}`;
    const previewPortalLink =
      this.state.identifier == null || this.state.identifier === '' ? null : (
        <React.Fragment>
          <div className="flex-row flex-row--noMargin u-columnGap--md u-rowGap--md u-width--100">
            <Button
              href={portalLink}
              target="_blank"
              rel="noopener noreferrer"
              type="ghost"
              size="small"
              style={{ padding: 'var(--input-padding)' }}
            >
              Open Returns Portal
            </Button>
            <CopyToClipboard text={portalLink}>
              <Button
                type="ghost"
                size="small"
                style={{ padding: 'var(--input-padding)' }}
              >
                Copy link
              </Button>
            </CopyToClipboard>
            <Input
              style={{ display: 'none' }}
              type="text"
              value={portalLink}
              id="portalLink"
            />
            <Button
              href="https://support.richreturns.io/en/articles/9272534-integrate-your-returns-portal"
              target="_blank"
              rel="noopener noreferrer"
              type="ghost"
              size="small"
              style={{ padding: 'var(--input-padding)' }}
            >
              View guide
            </Button>
          </div>
        </React.Fragment>
      );

    const uniqueIdentifier =
      this.state.brandName == null || this.state.brandName === '' ? (
        ''
      ) : (
        <React.Fragment>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Unique identifier</h4>
              <span>
                Enter a unique identifier for your brand. This is used in URLs.
                For a brand "Crazy Apples" the unique identifier would be
                "crazyapples". ONLY letters and numbers, NO spaces, dots, etc.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%' }}
                >
                  <h4>Unique identifier</h4>
                  <Search
                    defaultValue={this.state.identifier}
                    placeholder="Unique identifier for your brand"
                    onSearch={(value) => this.handleIdentifierChange(value)}
                    enterButton="Change"
                    style={{ margin: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <React.Fragment>
          <div
            className="flex-row flex-row--noMargin"
            style={{ width: '100%', marginBottom: 30 }}
          >
            <Alert
              message="Did you know?"
              description={
                <span className="TextBody TextBody--xxxs">
                  <div>
                    Learn how to easily integrate the Returns Portal into your store with our{' '}
                    <a
                      href="https://support.richreturns.io/en/articles/9272534-integrate-your-returns-portal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>INTEGRATION GUIDE</strong>
                    </a>
                  </div>
                  <div className="u-marginBottom--none">
                    If you are on our Pro-Plan or higher you can also customize
                    text elements of your returns portal. You can find this
                    setting under the Translations / Customize tab to your left
                    and read more about it{' '}
                    <a
                      href="https://support.richreturns.io/en/articles/9272515-customizing-the-language-text-on-all-pages"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here in our Docs
                    </a>
                  </div>
                </span>
              }
              type="info"
              showIcon
            />
          </div>

          <Form onSubmit={this.handleSubmit}>
            <PageButtonHeader
              headingTitle={'Brand customization'}
              headingRightContent={
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ padding: 'var(--input-padding)' }}
                  >
                    Save changes
                  </Button>
                </FormItem>
              }
            ></PageButtonHeader>
            <div className="flex-row flex-between-xxs u-marginTop--md">
              <div className="flex-col-md-3 flex-col-sm-12">
                <h4>Returns Portal</h4>
                <span>
                  Access the Returns Portal, copy a shareable link & view support articles.
                </span>
              </div>
              <div className="flex-col-md-8 flex-col-sm-12">
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    {previewPortalLink}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Brand name</h4>
                <span>Enter the brand name displayed to your customers.</span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <h4>Brand name</h4>
                    <FormItem>
                      {getFieldDecorator('brandName', {
                        initialValue: this.state.brandName,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a name for your store.',
                          },
                        ],
                      })(<Input placeholder="Brand name, e.g. 'Apple'" />)}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Store URL</h4>
                <span>
                  Enter the address to your store in the format
                  'https://www.yourStoreName.com'.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <h4>Store URL</h4>
                    <FormItem>
                      {getFieldDecorator('storeUrl', {
                        initialValue: this.state.storeUrl,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your URL.',
                          },
                        ],
                      })(
                        <Input placeholder="For example for Apple: https://www.apple.com" />
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Shopify store URL</h4>
                <span>
                  Enter your shopify store address, in the format
                  'https://www.site.myshopify.com'. In most cases we can
                  pre-fill this field and you don't have to change anything.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <h4>Shopify store URL</h4>
                    <FormItem>
                      {getFieldDecorator('storeShopifyUrl', {
                        initialValue: this.state.storeShopifyUrl,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your shopify store address.',
                          },
                        ],
                      })(
                        <Input placeholder="Brand name, e.g. 'For example for Apple: https://www.apple.com'" />
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            {uniqueIdentifier}
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Contact email</h4>
                <span>
                  Provide an email address for your customers to contact you in
                  case they need additional help with a return.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <h4>Recipient email</h4>
                    <FormItem>
                      {getFieldDecorator('contactEmail', {
                        initialValue: this.state.contactEmail,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a valid email.',
                          },
                        ],
                      })(
                        <Input placeholder="Your company's support email, e.g. hello@apple.com" />
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Contact URL (optional)</h4>
                <span>
                  Enabling this option will replace your contact email on the
                  Returns Portal with a URL. Customize the visible text in the
                  Translation settings.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Contact URL</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 10 }}
                  >
                    <FormItem>
                      {getFieldDecorator('contactUrl', {
                        initialValue: this.state.contactUrl,
                      })(
                        <Input
                          placeholder="Link to your Support Page or similar, needs to start with https://"
                          disabled={!this.state.enableContactUrl}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator(
                        'enableContactUrl',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.enableContactUrl}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable Contact URL
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Disable "Return to store" header (optional)</h4>
                <span>
                  Check this if you want to remove the top bar of the customer
                  portal ("Return to store").
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Disable "Return to store" header</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 10 }}
                  >
                    <FormItem>
                      {getFieldDecorator(
                        'removeBackToStoreHeader',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.removeBackToStoreHeader}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Disable Header
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>"Return policy" banner (optional)</h4>
                <span>
                  Enabling this option will add the return policy banner on the
                  return portal. Customize the visible text and the return
                  policy Url.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 10 }}
                  >
                    <div style={{ width: '100%' }}>
                      <h4>Return policy URL</h4>
                      <FormItem>
                        {getFieldDecorator('returnPolicyUrl', {
                          initialValue: this.state.returnPolicyUrl,
                          rules: [
                            {
                              required: this.state.enableReturnPolicy,
                              message:
                                'Please enter url to link your Return and Policy page.',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Link to your Return and Exchange Policy page, needs to start with https://"
                            disabled={!this.state.enableReturnPolicy}
                          />
                        )}
                      </FormItem>
                    </div>
                    <div style={{ width: '100%' }}>
                      <h4>Return policy text</h4>
                      <FormItem>
                        {getFieldDecorator('returnPolicy', {
                          initialValue: this.state.returnPolicy,
                          rules: [
                            {
                              required: this.state.enableReturnPolicy,
                              message: 'Please specify the return policy text.',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please specify the return policy text"
                            disabled={!this.state.enableReturnPolicy}
                          />
                        )}
                      </FormItem>
                    </div>
                    <FormItem>
                      {getFieldDecorator(
                        'enableReturnPolicy',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.enableReturnPolicy}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable Return Policy
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Disable Powered By RichReturns (optional)</h4>
                <span>
                  Check this option if you want to remove the Rich Returns mark
                  from your Return Portal.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Disable Powered By RichReturns</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('poweredByButtonEnabled', {
                        initialValue: !this.state.poweredByButtonEnabled, // Set the initial value based on your state
                        valuePropName: 'checked', // Map the 'checked' property to the form field value
                      })(
                        <Checkbox
                          disabled={
                            subscription.planName === 'standard' ? true : false
                          }
                          onChange={(e) =>
                            this.handleChange({
                              ...e,
                              target: {
                                ...e.target,
                                checked: !e.target.checked,
                              },
                            })
                          }
                        >
                          Disable to remove Rich Returns mark
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Favicon (optional)</h4>
                <span>
                  Upload your Favicon. Optimal dimensions for Favicon are 16x16
                  pixels but it can be 32x32 pixels. To provide your customers
                  with blazing fast pageload time the filesize is limited to
                  16kb and supported formats are .ico, .jpeg, .png and .svg.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    {this.state.faviconUrl === '' ||
                    this.state.faviconUrl === null ? (
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10, width: '100%' }}
                        >
                          <img
                            src="https://assets.reviews.io/img/all-global-assets/icons/icon-blank-photos--md.svg"
                            width="60"
                          ></img>
                          <Upload
                            beforeUpload={(file) => {
                              return this.checkFavicon(file);
                            }}
                            customRequest={(options) => {
                              this.handleFaviconUpload(options);
                            }}
                          >
                            <Button>Upload image</Button>
                          </Upload>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div
                            className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                            style={{ gap: 10, width: '100%' }}
                          >
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ gap: 10 }}
                            >
                              <Avatar
                                style={{ backgroundColor: '#52c41a' }}
                                icon="check"
                                size="small"
                              />
                              <span>Favicon uploaded</span>
                            </div>
                            <Popconfirm
                              title="Do you really want to delete the current favicon? You can upload a new one later."
                              onConfirm={() => {
                                this.handleDeleteFavicon();
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="ghost"
                                href="#"
                                size="small"
                                style={{ padding: 'var(--input-padding)' }}
                              >
                              Delete?
                              </Button>
                            </Popconfirm>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Logo (optional)</h4>
                <span>
                  Upload your logo. To provide your customers with blazing fast
                  pageload time the filesize is limited to 1Mb and supported
                  formats are .jpg, .png and .svg.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    {this.state.logoUrl === '' ||
                    this.state.logoUrl === null ? (
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10, width: '100%' }}
                        >
                          <img
                            src="https://assets.reviews.io/img/all-global-assets/icons/icon-blank-photos--md.svg"
                            width="60"
                          ></img>
                          <Upload
                            beforeUpload={(file) => {
                              return this.checkLogo(file);
                            }}
                            customRequest={(options) => {
                              this.handleLogoUpload(options);
                            }}
                          >
                            <Button>Upload image</Button>
                          </Upload>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div
                            className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                            style={{ gap: 10, width: '100%' }}
                          >
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ gap: 10 }}
                            >
                              <Avatar
                                style={{ backgroundColor: '#52c41a' }}
                                icon="check"
                                size="small"
                              />
                              <span>Logo uploaded</span>
                            </div>
                            <Popconfirm
                              title="Do you really want to delete the current logo? You can upload a new one later."
                              onConfirm={() => {
                                this.handleDeleteLogo();
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="ghost"
                                href="#"
                                size="small"
                                style={{ padding: 'var(--input-padding)' }}
                              >
                              Delete?
                              </Button>
                            </Popconfirm>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Background image (optional)</h4>
                <span>
                  If you want to show a full-screen background image to your
                  customers, upload it here. To provide your customers with
                  blazing fast pageload time the filesize is limited to 2Mb and
                  the only supported formats are .jpg, .jpeg and .png.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    {this.state.backgroundImageUrl === '' ||
                    this.state.backgroundImageUrl === null ? (
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10, width: '100%' }}
                        >
                          <img
                            src="https://assets.reviews.io/img/all-global-assets/icons/icon-blank-photos--md.svg"
                            width="60"
                          ></img>
                          <Upload
                            beforeUpload={(file) => {
                              return this.checkBackground(file);
                            }}
                            customRequest={(options) => {
                              this.handleBackgroundUpload(options);
                            }}
                          >
                            <Button>Upload image</Button>
                          </Upload>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div
                            className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                            style={{ gap: 10, width: '100%' }}
                          >
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs"
                              style={{ gap: 10 }}
                            >
                              <Avatar
                                style={{ backgroundColor: '#52c41a' }}
                                icon="check"
                                size="small"
                              />
                              <span>Background uploaded</span>
                            </div>
                            <Popconfirm
                              title="Do you really want to delete the current background? You can upload a new one later."
                              onConfirm={() => {
                                this.handleDeleteBackground();
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="ghost"
                                href="#"
                                size="small"
                                style={{ padding: 'var(--input-padding)' }}
                              >
                              Delete?
                              </Button>
                            </Popconfirm>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Font Style</h4>
                <span>
                  Set a font style for the text on return-portal page. For
                  default style see the return-portal page.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Set font style</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('brandFontStyle', {
                        initialValue: this.state.brandFontStyle,
                      })(
                        <Select>
                          <Option value="normal">Normal</Option>
                          <Option
                            value="italic"
                            style={{ fontStyle: 'italic' }}
                          >
                            Italic
                          </Option>
                          <Option
                            value="oblique"
                            style={{ fontStyle: 'oblique' }}
                          >
                            Oblique
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Background color (optional)</h4>
                <span>
                  To customize the return portal with your brand color, enter
                  the hex code. Note: if you've uploaded a background image
                  above, it will take priority over the brand color.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('brandColor', {
                        initialValue: this.state.brandColor,
                        setFieldsValue: this.state.brandColor,
                      })(
                        // <Input onChange={(event) => this.handleColorChange(event)} />
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10 }}
                        >
                          <Tag
                            color={this.state.brandColor}
                            style={{
                              color: this.isColorLight(this.state.brandColor)
                                ? 'black'
                                : 'white',
                              borderColor: '#d9d9d9',
                            }}
                          >
                            Preview: {this.state.brandColor}
                          </Tag>
                          <TwitterPicker
                            color={this.state.brandColor}
                            onChange={(color, event) => {
                              this.handleColorChange(color, event);
                            }}
                            triangle="hide"
                          />
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Button color (optional)</h4>
                <span>
                  Set a color for the button on the return-portal page. For
                  default color see the return-portal page.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('brandColorButton', {
                        initialValue: this.state.brandColorButton,
                        setFieldsValue: this.state.brandColorButton,
                      })(
                        // <Input onChange={(event) => this.handleColorChange(event)} />
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10 }}
                        >
                          <Tag
                            color={this.state.brandColorButton}
                            style={{
                              color: this.isColorLight(
                                this.state.brandColorButton
                              )
                                ? 'black'
                                : 'white',
                              borderColor: '#d9d9d9',
                            }}
                          >
                            Preview: {this.state.brandColorButton}
                          </Tag>
                          <TwitterPicker
                            color={this.state.brandColorButton}
                            onChange={(color, event) => {
                              this.handleColorChangeButton(color, event);
                            }}
                            handleChangeComplete={(color, event) => {
                              this.handleColorChangeButton(color, event);
                            }}
                            // triangle="hide"
                          />
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Button text color (optional)</h4>
                <span>
                  Set the color of the text within the button. For default color
                  see the return-portal page.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('brandColorButtonText', {
                        initialValue: this.state.brandColorButtonText,
                        setFieldsValue: this.state.brandColorButtonText,
                      })(
                        // <Input onChange={(event) => this.handleColorChange(event)} />
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10 }}
                        >
                          <Tag
                            color={this.state.brandColorButtonText}
                            style={{
                              color: this.isColorLight(
                                this.state.brandColorButtonText
                              )
                                ? 'black'
                                : 'white',
                              borderColor: '#d9d9d9',
                            }}
                          >
                            Preview: {this.state.brandColorButtonText}
                          </Tag>
                          <TwitterPicker
                            color={this.state.brandColorButtonText}
                            onChange={(color, event) => {
                              this.handleColorChangeButtonText(color, event);
                            }}
                            triangle="hide"
                          />
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Header/Footer text color (optional)</h4>
                <span>
                  Set the color of the header text i.e. "Return and Exchanges"
                  and Footer i.e. "Contact us at...". For default color see the
                  return-portal page.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%' }}
                  >
                    <FormItem>
                      {getFieldDecorator('brandColorText', {
                        initialValue: this.state.brandColorText,
                        setFieldsValue: this.state.brandColorText,
                      })(
                        // <Input onChange={(event) => this.handleColorChange(event)} />
                        <div
                          className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                          style={{ gap: 10 }}
                        >
                          <Tag
                            color={this.state.brandColorText}
                            style={{
                              color: this.isColorLight(
                                this.state.brandColorText
                              )
                                ? 'black'
                                : 'white',
                              borderColor: '#d9d9d9',
                            }}
                          >
                            Preview: {this.state.brandColorText}
                          </Tag>
                          <TwitterPicker
                            color={this.state.brandColorText}
                            onChange={(color, event) => {
                              this.handleColorChangeText(color, event);
                            }}
                            triangle="hide"
                          />
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Divider></Divider>
            {this.context.featureCustomerIncentives && (
              <React.Fragment>
                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Customer Incentives Badge Color</h4>
                    <span>
                      Set the color of the text within the badge. For default
                      color see the return-portal page.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%' }}
                      >
                        <FormItem>
                          {getFieldDecorator('brandIncentivesColor', {
                            initialValue: this.state.brandIncentivesColor,
                            setFieldsValue: this.state.brandIncentivesColor,
                          })(
                            // <Input onChange={(event) => this.handleColorChange(event)} />
                            <div
                              className="flex-row flex-row--noMargin flex-middle-xxs flex-between-xxs"
                              style={{ gap: 10 }}
                            >
                              <Tag
                                color={this.state.brandIncentivesColor}
                                style={{
                                  color: this.isColorLight(
                                    this.state.brandIncentivesColor
                                  )
                                    ? 'black'
                                    : 'white',
                                  borderColor: '#d9d9d9',
                                }}
                              >
                                Preview: {this.state.brandIncentivesColor}
                              </Tag>
                              <TwitterPicker
                                color={this.state.brandIncentivesColor}
                                onChange={(color, event) => {
                                  this.handleColorChangeBadgeText(color, event);
                                }}
                                triangle="hide"
                              />
                            </div>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider></Divider>
                {/* <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Customer Incentives Badge Color</h4>
                  <span>
                    Set the color of the text within the badge. For default
                    color see the return-portal page.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('brandIncentivesColor', {
                      initialValue: this.state.brandIncentivesColor,
                      setFieldsValue: this.state.brandIncentivesColor,
                    })(
                      <Tag
                        color={this.state.brandIncentivesColor}
                        style={{
                          color: this.isColorLight(
                            this.state.brandIncentivesColor
                          )
                            ? 'black'
                            : 'white',
                          borderColor: '#d9d9d9',
                        }}
                      >
                        Preview: {this.state.brandIncentivesColor}
                      </Tag>
                    )}
                  </FormItem>
                  <TwitterPicker
                    color={this.state.brandIncentivesColor}
                    onChange={(color, event) => {
                      this.handleColorChangeBadgeText(color, event);
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row> */}
              </React.Fragment>
            )}
            {this.state.brandFontSize && (
              <React.Fragment>
                {/* <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
                style={{ height: 150 }}
              >
                <Col span={10}>
                  <h4>Header/Footer font size (Pixels)</h4>
                  <span>
                    Set the font size of the header text i.e. "Return and
                    Exchanges" and Footer i.e. "Contact us at...". You can
                    choose a number from 14 to 26. For default size see the
                    return-portal page.
                  </span>
                </Col>
                <Col span={10} style={{ height: '100%', marginTop: 30 }}>
                  <FormItem>
                    {getFieldDecorator('brandFontSize', {
                      initialValue: this.state.brandFontSize,
                    })(
                      <InputNumber
                        min={14}
                        max={26}
                        step={1}
                        onChange={this.handleFontSizeChange}
                      />
                    )}
                  </FormItem>
                  <p style={{ fontSize: parseInt(this.state.brandFontSize) }}>
                    Test your font size here: {this.state.brandFontSize}px
                  </p>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row> */}
                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Header/Footer font size (Pixels)</h4>
                    <span>
                      Set the font size for the header text ("Returns and
                      Exchanges") and the footer text ("Contact us at...").
                      Choose a value between 14 and 26. For the default size,
                      refer to the return portal page.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Preview font size</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{
                          width: '100%',
                          flexDirection: 'column',
                          gap: 10,
                        }}
                      >
                        <FormItem>
                          {getFieldDecorator('brandFontSize', {
                            initialValue: this.state.brandFontSize,
                          })(
                            <InputNumber
                              min={14}
                              max={26}
                              step={1}
                              onChange={this.handleFontSizeChange}
                              style={{ width: '100%' }}
                            />
                          )}
                        </FormItem>
                        <div
                          style={{
                            fontSize: parseInt(this.state.brandFontSize),
                          }}
                        >
                          Test your font size here: {this.state.brandFontSize}
                          px
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider></Divider>
              </React.Fragment>
            )}
            <Divider></Divider>
            <Row
              type="flex"
              justify="end"
              className="dashboard-content u-marginBottom--lg"
            >
              <Col>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ padding: 'var(--input-padding)' }}
                  >
                    Save changes
                  </Button>
                </FormItem>
              </Col>
            </Row>
            {/*
          {this.state.brandFontSize && (
            <React.Fragment>
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
                style={{ height: 150 }}
              >
                <Col span={10}>
                  <h4>Header/Footer font size (Pixels)</h4>
                  <span>
                    Set the font size of the header text i.e. "Return and
                    Exchanges" and Footer i.e. "Contact us at...". You can
                    choose a number from 14 to 26. For default size see the
                    return-portal page.
                  </span>
                </Col>
                <Col span={10} style={{ height: '100%', marginTop: 30 }}>
                  <FormItem>
                    {getFieldDecorator('brandFontSize', {
                      initialValue: this.state.brandFontSize,
                    })(
                      <InputNumber
                        min={14}
                        max={26}
                        step={1}
                        onChange={this.handleFontSizeChange}
                      />
                    )}
                  </FormItem>
                  <p style={{ fontSize: parseInt(this.state.brandFontSize) }}>
                    Test your font size here: {this.state.brandFontSize}px
                  </p>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="dashboard-content"
          >
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </FormItem>
            </Col>
          </Row> */}
          </Form>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const WrappedBrand = Form.create()(Brand);
export default WrappedBrand;