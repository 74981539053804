import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  Form,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

class GiftReturnsWithInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      buttonLoading: false,
      orderSearchError: undefined,
    };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.props.onOk();
  };

  handleSave = () => {
    // this.props.onSave();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.handleSubmit(values);
      }
    });
  };

  async handleSubmit(values) {
    try {
      this.setState({
        buttonLoading: true,
        orderSearchError: undefined,
      });

      const companyIdentifier = this.props.companyIdentifier;
      let {
        orderNumber,
        orderZipCode,
        giftReturnFirstName,
        giftReturnLastName,
        giftReturnCompany,
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      } = values;
      orderNumber = orderNumber.trim();
      orderZipCode = orderZipCode.trim();
      const response = await axios.post('/api/visitors/return/products', {
        orderNumber,
        orderEmail: orderZipCode,
        companyIdentifier,
        typeReturn: 'giftReturn',
        giftReturnFirstName,
        giftReturnLastName,
        giftReturnCompany,
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      });
      if (response?.data?.status === 'error') {
        const { intl } = this.props;
        const errorMessage = response.data.error;
        const productDeleted = response.data.productDeleted;
        let translationId;
        let translationDescription;
        let translationDefaultMessage;
        if (errorMessage === 'orderNotFound') {
          translationId = 'app.visitor.newReturn.errorOrderNotFound';
          translationDescription = 'Error when order is not found in Shopify.';
          translationDefaultMessage = 'Cannot find your order.';
        } else if (errorMessage === 'returnExists') {
          translationId = 'app.visitor.newReturn.errorReturnExists';
          translationDescription =
            'Error when there is an existing return in the system.';
          translationDefaultMessage = 'Return already exists.';
        } else if (errorMessage === 'returnTooLate') {
          translationId = 'app.visitor.newReturn.errorReturnTooLate';
          translationDescription = 'Error when outside of allowed timeframe.';
          translationDefaultMessage =
            'Sorry this return is outside of our allowed timeframe for returning products.';
        } else if (errorMessage === 'returnExcluded') {
          translationId = 'app.visitor.newReturn.errorReturnExcluded';
          translationDescription =
            'Error when excluded from being returned by merchant.';
          translationDefaultMessage =
            "Sorry we're unable to offer you a return as your item(s) were on sale.";

          // new
        } else if (errorMessage === 'returnUnfulfilled') {
          translationId = 'app.visitor.newReturn.errorReturnUnfulfilled';
          translationDescription =
            'Error when excluded because unfulfilled order.';
          translationDefaultMessage =
            'It looks like your order has not shipped, yet. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'returnUnpaid') {
          translationId = 'app.visitor.newReturn.errorReturnUnpaid';
          translationDescription = 'Error when excluded because unpaid order.';
          translationDefaultMessage =
            'It looks like your order has not been paid, yet, or has already been refunded. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'returnCancelled') {
          translationId = 'app.visitor.newReturn.errorReturnCancelled';
          translationDescription =
            'Error when excluded because cancelled order.';
          translationDefaultMessage =
            'It looks like your order has been cancelled. Therefore we cannot process a return for it.';
        } else if (errorMessage === 'orderInvalid' && productDeleted) {
          translationId = 'app.visitor.newReturn.errorProductDeleted';
          translationDescription =
            'Error when excluded because e.g. order contains deleted products.';
          translationDefaultMessage = `All of the products in your order have been removed from the store and cannot be returned through the portal. Contact the store’s support email for more details.`;
        } else if (errorMessage === 'orderInvalid') {
          translationId = 'app.visitor.newReturn.errorOrderInvalid';
          translationDescription =
            'Error when excluded because e.g. order contains deleted products.';
          translationDefaultMessage =
            'It looks like there was an issue requesting details for your order. Please contact customer support.';
        }
        message.error(
          intl.formatMessage({
            id: translationId,
            description: translationDescription,
            defaultMessage: '',
          }),
          15
        );
        return this.setState({
          translationId,
          translationDescription,
          translationDefaultMessage: '',
          orderSearchError: errorMessage,
          buttonLoading: false,
        });
      }
      this.props.updateBrandSettings({
        ...response.data,
      });
      this.setState({
        orderSearchError: undefined,
      });
      this.props.onCancel();
      return this.props.history.push('/return/order');
    } catch (err) {
      console.error(err);
      message.error(
        'Unable to search your order at the moment, Please confirm products are available in store.',
        4
      );
      this.setState({
        buttonLoading: false,
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl;
    const title = (
      <div>
        <Icon type="gift" style={{ paddingRight: '10px' }} />
        <FormattedMessage
          id="app.visitor.newReturn.returnYourGift"
          description="Placeholder for order number input"
          defaultMessage="Return Your Gift"
        />
      </div>
    );
    return (
      <React.Fragment>
        <Form colon={false}>
          <Row>
            <h3>
              <FormattedMessage
                id="app.visitor.newReturn.orderInformation"
                description="Order Information"
                defaultMessage="Information from the original order."
                values={{}}
              />
            </h3>
          </Row>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.orderNumber"
                description="Enter the order number from the original order."
                defaultMessage="Order Number"
              />
            }
          >
            {getFieldDecorator('orderNumber', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.orderNumber.warning"
                      description="Warning if user does not enter the order number"
                      defaultMessage="Please enter your order number"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.orderNumberPlaceholder',
                  description: 'Placeholder for order number input',
                  defaultMessage:
                    'Enter the order number from the original order.',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.orderZipCodePlaceholder"
                description="Placeholder for order zip code input"
                defaultMessage="Enter the zipcode from the original order."
              />
            }
          >
            {getFieldDecorator('orderZipCode', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.zipCode.warning"
                      description="Warning if user does not enter the zip code"
                      defaultMessage="Please enter your zip code"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.orderZipCode',
                  description: 'Placeholder for order zip code input',
                  defaultMessage: 'Order Zip Code',
                })}
              />
            )}
          </Form.Item>
          <Row style={{ paddingTop: '20px' }}>
            <h3>
              <FormattedMessage
                id="app.visitor.newReturn.customerInformation"
                description="Customer Information"
                defaultMessage="Your Personal Information."
                values={{}}
              />
            </h3>
          </Row>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnFistName"
                description="Label for customer name input"
                defaultMessage="Your first name"
              />
            }
          >
            {getFieldDecorator('giftReturnFirstName', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnFistName.warning"
                      description="Warning if user does not enter the name"
                      defaultMessage="Please enter your first name"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnFistNamePlaceholder',
                  description: 'Placeholder for customer name input',
                  defaultMessage: 'Your first name',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnLastName"
                description="Label for customer name input"
                defaultMessage="Your last name"
              />
            }
          >
            {getFieldDecorator('giftReturnLastName', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnLastName.warning"
                      description="Warning if user does not enter the name"
                      defaultMessage="Please enter your last name"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnLastNamePlaceholder',
                  description: 'Placeholder for customer name input',
                  defaultMessage: 'Your last name',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnCompany"
                description="Label for customer's company input"
                defaultMessage="Your company"
              />
            }
          >
            {getFieldDecorator('giftReturnCompany', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnCompany.warning"
                      description="Warning if user does not enter the company"
                      defaultMessage="Please enter your company"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnCompanyPlaceholder',
                  description: "Placeholder for customer's company input",
                  defaultMessage: 'Your company',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.emailGift"
                description="Placeholder for email input"
                defaultMessage="Email"
              />
            }
          >
            {getFieldDecorator('giftReturnEmail', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.emailGift.warning"
                      description="Warning if user does not enter the email address"
                      defaultMessage="Please enter your email address"
                      values={{}}
                    />
                  ),
                },
                // { type: 'email', message: 'Please enter a valid email' },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.emailGiftPlaceholder',
                  description: 'Placeholder for email input',
                  defaultMessage: 'Enter your email address',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.phone"
                description="Placeholder for phone number input"
                defaultMessage="Phone Number"
              />
            }
          >
            {getFieldDecorator('giftReturnPhone', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.phone.warning"
                      description="Warning if user does not enter the phone number"
                      defaultMessage="Please enter your phone number"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.phonePlaceholder',
                  description: 'Placeholder for phone number input',
                  defaultMessage: 'Enter your phone number',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.address"
                description="Label for address input"
                defaultMessage="Address / Street"
              />
            }
          >
            {getFieldDecorator('giftReturnAddress', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.address.warning"
                      description="Warning if user does not enter the address"
                      defaultMessage="Please enter your address"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.addressPlaceholder',
                  description: 'Placeholder for address input',
                  defaultMessage: 'Enter your street information',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.zipCode"
                description="Placeholder for zip code input"
                defaultMessage="Zip Code"
              />
            }
          >
            {getFieldDecorator('giftReturnZipCode', {
              rules: [
                {
                  required: true,
                  message: {
                    message: (
                      <FormattedMessage
                        id="app.visitor.newReturn.zipCode.warning"
                        description="Warning if user does not enter the zip code"
                        defaultMessage="Please enter your zip code"
                        values={{}}
                      />
                    ),
                  },
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.zipCodePlaceholder',
                  description: 'Placeholder for zip code input',
                  defaultMessage: 'Enter the zipcode from your address',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.city"
                description="Placeholder for city input"
                defaultMessage="City"
              />
            }
          >
            {getFieldDecorator('giftReturnCity', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.city.warning"
                      description="Warning if user does not enter the city"
                      defaultMessage="Please enter your city"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.cityPlaceholder',
                  description: 'Placeholder for city input',
                  defaultMessage: 'Enter the city where you reside/live.',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.state"
                description="Placeholder for state input"
                defaultMessage="State"
              />
            }
          >
            {getFieldDecorator('giftReturnState', {
              rules: [
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.state.warning"
                      description="Warning if user does not enter the state"
                      defaultMessage="Please enter your state"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.statePlaceholder',
                  description: 'Placeholder for state input',
                  defaultMessage: 'Enter the state where you reside/live',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.stateCode"
                description="Placeholder for state code input"
                defaultMessage="State Code"
              />
            }
          >
            {getFieldDecorator('giftReturnStateCode', {
              rules: [
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.stateCode.warning"
                      description="Warning if user does not enter the state code"
                      defaultMessage="Please enter your state code"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.stateCodePlaceholder',
                  description: 'Placeholder for state code input',
                  defaultMessage:
                    'Enter the state code from the state where you reside/live',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.country"
                description="Placeholder for country input"
                defaultMessage="Country"
              />
            }
          >
            {getFieldDecorator('giftReturnCountry', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.country.warning"
                      description="Warning if user does not enter the country"
                      defaultMessage="Please enter your country"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.countryPlaceholder',
                  description: 'Placeholder for country input',
                  defaultMessage: 'Enter the country where you reside/live',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.countryCode"
                description="Placeholder for country code input"
                defaultMessage="Country Code"
              />
            }
          >
            {getFieldDecorator('giftReturnCountryCode', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.countryCode.warning"
                      description="Warning if user does not enter the country code"
                      defaultMessage="Please enter your country code"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.countryCodePlaceholder',
                  description: 'Placeholder for country code input',
                  defaultMessage: 'Enter the country code from your country',
                })}
              />
            )}
          </Form.Item>
        </Form>
        <Row
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20px',
          }}
        >
          {/* <Button type="primary" onClick={this.handleSave}>
              <span style={{ width: 200 }}>Next</span>
            </Button> */}
          <Button
            id="new-gift-return"
            style={{
              width: '100%',
              backgroundColor: this.props.brandColorButton,
              borderColor: this.props.brandColorButton,
            }}
            onClick={this.handleSave}
          >
            <span
              style={{
                color: this.props.brandColorButtonText,
                fontStyle: this.props.brandFontStyle,
              }}
            >
              <FormattedMessage
                id="app.visitor.newReturn.buttonStartGiftReturn"
                description="Button to start a Gift Exchange"
                defaultMessage="Start a Gift Exchange"
                values={{}}
              />
            </span>
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

const WrappedGiftReturns = Form.create()(GiftReturnsWithInfo);

export default WrappedGiftReturns;
