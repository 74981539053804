/**
 * Component for our Return details sidebar that
 * lets users view and edit the details of a Return.
 *
 * @component
 */

import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Drawer,
  Icon,
  message,
  Select,
  Tag,
  Tooltip,
  Input,
} from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';
import ReturnDetailsCustomer from './ReturnDetailsCustomer';
import ReturnDetailsExchange from './ReturnDetailsExchange';
import ReturnDetailsLabelAutomatic from './ReturnDetailsLabelAutomatic';
import ReturnDetailsLabelManual from './ReturnDetailsLabelManual';
import ReturnDetailsNoteCustomer from './ReturnDetailsNoteCustomer';
import ReturnDetailsNoteStaff from './ReturnDetailsNoteStaff';
import ReturnDetailsPhotoUpload from './ReturnDetailsPhotoUpload';
import ReturnDetailsProduct from './ReturnDetailsProduct';
import ReturnDetailsRefundButton from './ReturnDetailsRefundButton';
import ReturnDetailsTimeline from './ReturnDetailsTimeline';
import StripeWebhookDetails from './StripeWebhookDetails';
import ReturnDetails from './ReturnDetails';
import ReturnGiftDetails from './ReturnGiftDetail';

const Option = Select.Option;
const { TextArea } = Input;

const pluralize = (word, count) => (count === 1 ? word : `${word}s`);

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds
 * @returns {string} Formatted time in days and hours
 */
// eslint-disable-next-line no-unused-vars
const formatTime = (seconds) => {
  if (Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if (!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if (!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize(
    'hour',
    hours
  )}`;
};

class ReturnManagement extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      stateOfReturn: null,
      notesKey: undefined,
      isLoading: true,
      drawerVisible: false,
      returnMethod: undefined,
      returnObject: null,
      loading: true,
      productsOfReturn: null,
      labelService: null,
      shipDate: null,
      labelStatus: null,
      storeAddress: [],
      selectedStoreAddress: null,
      useGiftApiForStoreCredit: undefined,
      useDiscountCodeForStoreCredit: undefined,
      useStoreCredit: undefined,
      width: '50%',
      discountCodeStatusTracking: true,
      rejectionObservation: null,
      showObservationField: false,
    };
  }

  handleGetReturn = async () => {
    try {
      const response = await axios.post(`/api/returns/${this.props.returnId}`);
      if (response.data.labelService && response.data.labelService.length > 0) {
        this.setState({
          labelService: response.data.labelService[0].labelService,
          // fetch {useGiftApiForStoreCredit, useDiscountCodeForStoreCredit} from returnSettings
          useGiftApiForStoreCredit:
            response.data.labelService[0].useGiftApiForStoreCredit,
          useDiscountCodeForStoreCredit:
            response.data.labelService[0].useDiscountCodeForStoreCredit,
          useStoreCredit:
            response.data.labelService[0].useStoreCredit,
          isLoading: false,
        });
      }
      if (response?.data?.returnObject?.returnStatus === 'Rejected') {
        this.setState({
          showObservationField: true,
          rejectionObservation: response.data.returnObject.rejectionObservation,
          isLoading: false,
        });
      }
      this.setState({
        returnObject: response.data.returnObject,
        productsOfReturn: [...response.data.productsOfReturn],
        isLoading: false,
        labelStatus: response.data.returnObject.labelStatus,
      });
    } catch (err) {
      return message.error(
        'Error getting return details. Please try again.',
        5
      );
    }
  };

  componentDidMount = async () => {
    this.handleGetReturn();
  };

  render() {
    const { returnObject } = this.state;
    // draftReturn is a return that has been created but did not have an orderId
    const draftReturn = (returnObject?.type === 'giftReturn' && !returnObject?.orderId) ? true : false;
    return (
      <React.Fragment>
        {!this.state.isLoading && !draftReturn ? (
          <ReturnDetails
            returnId={this.props.returnId}
            drawerVisible={this.props.drawerVisible}
            resolutionOptions={this.props.resolutionOptions}
            hideDrawer={this.props.hideDrawer}
            updateData={this.props.updateData}
            setVisibilityOfResetSearchButton={
              this.props.setVisibilityOfResetSearchButton
            }
          />
        ) : (
          ''
        )}

        {!this.state.isLoading && draftReturn ? (
          <ReturnGiftDetails
            returnId={this.props.returnId}
            drawerVisible={this.props.drawerVisible}
            resolutionOptions={this.props.resolutionOptions}
            hideDrawer={this.props.hideDrawer}
            updateData={this.props.updateData}
            setVisibilityOfResetSearchButton={
              this.props.setVisibilityOfResetSearchButton
            }
            handleGetReturn={this.handleGetReturn}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default ReturnManagement;
