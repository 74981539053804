import { Badge, Button, Tooltip } from 'antd';
import React, { Component } from 'react';
class StripeWebhookDetails extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { paymentErrorMessage } = this.props.returnObject;
    let formattedMessage;

    if (paymentErrorMessage.includes("There was an error authenticating your secret key. Please verify the information you have entered here.")) {
      formattedMessage = (
        <span>
          There was an error authenticating your secret key. Please verify the information you have entered <a href="/dashboard/connect/platform" target="_blank">here</a>.
        </span>
      );
    } else {
      formattedMessage = paymentErrorMessage;
    }
    return (
      <React.Fragment>
        {this.props.paymentError ? (
          <React.Fragment>
            <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
              <div className="flex-col-xxs-12">
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs"
                  style={{ gap: 5 }}
                >
                  <strong>Payment Error</strong>
                  <Tooltip placement="bottom" title={'Stripe Webhook Error'}>
                    <Badge status="error" />
                  </Tooltip>
                </div>
              </div>
              <div className="flex-col-sm-6 flex-col-xxs-12">
                <Tooltip
                  placement="bottom"
                  title={formattedMessage}
                >
                  <Button
                    size="small"
                    type="ghost"
                    style={{ padding: 'var(--input-padding)', width: '100%' }}
                  >
                    View details
                  </Button>
                </Tooltip>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <div
                className="flex-row flex-row--noMargin flex-middle-xxs"
                style={{ gap: 5 }}
              >
                <strong>{this.props.headingText}</strong>
                <Tooltip placement="bottom" title={`${this.props.text}`}>
                  <Badge status="success" />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default StripeWebhookDetails;
