import React from 'react';
import {
  Row,
  Col,
  Divider,
  Checkbox,
  Alert,
  Button,
  Table,
  message,
  Popconfirm,
  Badge,
  Tooltip,
} from 'antd';
import axios from 'axios';
import ReturnLabel from './ReturnLabel';
import { WEIGHT_UNIT, LENGTH_UNIT } from '../../../../constants/constants';
import { IconTray } from '../../../../components/CustomReturnsIcons';

class Carrier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      cheapestCarrier: false,
      carrier: {},
      carriers: [],
      upsEnabled: false,
    };
  }
  columns = [
    {
      title: 'Name service',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        <React.Fragment>
          {active ? (
            <div className="u-width--100" style={{ textAlign: 'center' }}>
              <Tooltip placement="top" title={'Carrier is active'}>
                <Badge status="success" />
              </Tooltip>
            </div>
          ) : (
            <div className="u-width--100" style={{ textAlign: 'center' }}>
              <Tooltip
                placement="top"
                title={'You need to complete setup to use this carrier.'}
              >
                <Badge status="error" />
              </Tooltip>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Parcel Weight Unit',
      dataIndex: 'labelParcelWeightUnit',
      key: 'labelParcelWeightUnit',
      render: (labelParcelWeightUnit) => (
        <span>{WEIGHT_UNIT[labelParcelWeightUnit]}</span>
      ),
    },
    {
      title: 'Parcel Default Weigth',
      dataIndex: 'labelParcelDefaultWeigth',
      key: 'labelParcelDefaultWeigth',
      render: (labelParcelDefaultWeigth) => (
        <span>{parseFloat(labelParcelDefaultWeigth).toFixed(2)}</span>
      ),
    },
    {
      title: 'Parcel Dimension Unit',
      dataIndex: 'labelParcelDimensionsUnit',
      key: 'labelParcelDimensionsUnit',
      render: (labelParcelDimensionsUnit) => (
        <span>{LENGTH_UNIT[labelParcelDimensionsUnit]}</span>
      ),
    },
    {
      title: 'Parcel Length',
      dataIndex: 'labelParcelLength',
      key: 'labelParcelLength',
      render: (labelParcelLength) => (
        <span>{parseFloat(labelParcelLength).toFixed(2)}</span>
      ),
    },
    {
      title: 'Parcel Width',
      dataIndex: 'labelParcelWidth',
      key: 'labelParcelWidth',
      render: (labelParcelWidth) => (
        <span>{parseFloat(labelParcelWidth).toFixed(2)}</span>
      ),
    },
    {
      title: 'Parcel Height',
      dataIndex: 'labelParcelHeight',
      key: 'labelParcelHeight',
      render: (labelParcelHeight) => (
        <span>{parseFloat(labelParcelHeight).toFixed(2)}</span>
      ),
    },
    {
      title: 'Default',
      key: 'default',
      render: (text, record, index) => (
        <React.Fragment>
          {record.isDefault ? (
            <div>Yes</div>
          ) : (
            <Popconfirm
              title="Are you sure you want to set this carrier as default.?"
              onConfirm={() => this.setDefaultCarrier(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Set</Button>
            </Popconfirm>
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'id',
      key: 'actionEdit',
      render: (id) => (
        <React.Fragment>
          <Button
            size="small"
            onClick={() => {
              this.editCarrier(id);
            }}
          >
            Edit
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'id',
      key: 'actionDelete',
      render: (id) => (
        <React.Fragment>
          <Popconfirm
            title="Are you sure you want to delete.?"
            onConfirm={() => this.deleteCarrier(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
        </React.Fragment>
      ),
    },
  ];

  setDefaultCarrier = async (id) => {
    try {
      console.log('id', id);
      const res = await axios.post('/api/carrierSettings/set-default', { id });
      if (res.data.success) {
        message.success('Default carrier set');
        this.getCarriers();
      }
    } catch (error) {
      message.error('Error setting default carrier');
    }
  };

  showConfigureModal = () => {
    this.setState({ showModal: !this.state.showModal });
    this.getCarriers();
  };

  getCarriers = async () => {
    try {
      this.setState({ loading: true });
      const response = await axios.get('/api/carrierSettings');
      this.setState({
        loading: false,
        carriers: response.data.carriers,
        carrier: {},
        cheapestCarrier: response.data.labelSelectCheapestCarrier,
        upsEnabled: response.data.upsEnabled,
      });
    } catch (error) {
      this.setState({ loading: false });
      message.error('Error getting carriers');
    }
  };

  editCarrier(id) {
    this.setState({
      carrier: this.state.carriers.find((carrier) => carrier.id === id),
      showModal: true,
    });
  }

  deleteCarrier = async (id) => {
    try {
      this.setState({ loading: true });
      await axios.post('/api/carrierSettings/delete', { id });
      this.getCarriers();
    } catch (error) {
      this.setState({ loading: false });
      message.error('Error deleting carrier');
    }
  };

  updateCheapestCarrier = async () => {
    try {
      const currentValue = this.state.cheapestCarrier;
      const value = !currentValue;
      this.setState({ loading: true, cheapestCarrier: value });
      await axios.post('/api/carrierSettings/cheapest', {
        labelSelectCheapestCarrier: value,
      });
      this.setState({ loading: false });
      message.success('Feature cheapest carrier updated');
    } catch (error) {
      this.setState({ loading: false });
      message.error('Error updating cheapest carrier');
    }
  };

  componentDidMount() {
    this.getCarriers();
  }

  render() {
    return (
      <div className="ContentPanel  u-paddingTop--sm">
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Prepaid Labels</h1>
            <p>Set up your prepaid label</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={24}>
            <Alert
              message="Important"
              description={
                <span>
                  This configuration section allows you to setup multiple
                  integrations with carriers or shipping platforms alike. For
                  more information, read our guide{' '}
                  <a
                    href="https://support.richreturns.io/en/articles/10278569-multiple-carrier-integrations"
                    target="_blank"
                  >
                    here.
                  </a>{' '}
                  Please note that you will still have to turn on the option for
                  your customers in the Configuration → Returns → Return methods
                  section.
                </span>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>

        <Row style={{ marginTop: '25px' }}>
          <Col span={24}>
            <Popconfirm
              title={
                this.state.cheapestCarrier
                  ? 'Are you sure you want to disable the cheapest carrier feature.?'
                  : 'Are you sure you want to enable the cheapest carrier feature.?'
              }
              onConfirm={() => this.updateCheapestCarrier()}
              okText="Yes"
              cancelText="No"
            >
              <Checkbox
                checked={this.state.cheapestCarrier}
                // onChange={(e) => this.updateCheapestCarrier(e.target.checked)}
                disabled={this.state.carriers.length === 0}
              >
                Use cheapest carrier
              </Checkbox>
            </Popconfirm>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Table
            columns={this.columns}
            dataSource={this.state.carriers}
            rowKey={(_, index) => index}
            pagination={false}
            bordered
            locale={{
              emptyText: (
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">No Data</p>
                  </div>
                </div>
              ),
            }}
          />
        </Row>
        {this.state.carriers.length === 0 && (
          <Divider style={{ marginTop: 0 }} />
        )}
        {/* <Row style={{ paddingTop: '20px' }}>
          <Col span={24}>
            <Button type="primary" onClick={() => this.showConfigureModal()}>
              Add new carrier
            </Button>
          </Col>
        </Row> */}
        <Row>
          {this.state.showModal && (
            <ReturnLabel
              key="returnLabel"
              showConfigureModal={this.showConfigureModal}
              state={this.state}
              setParentState={(values) => this.setState({ ...values })}
              carrier={this.state.carrier}
              upsEnabled={this.state.upsEnabled}
            />
          )}
        </Row>
      </div>
    );
  }
}

export default Carrier;
