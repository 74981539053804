/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our  ShipStation
 * integrations for the user.
 *
 * @component
 */
import {
  Button,
  Col,
  Divider,
  Icon,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Checkbox,
} from 'antd';
import axios from "axios";
import React, { Component } from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';

const { Option } = Select;


class AusPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deliveryServiceList: []
    };
  }

  // Execute the getDeliveryServiceList function when the component mounts
  componentDidMount() {
    if (this.props.labelApiKey != null && this.props.labelApiKey !== "" && this.props.labelPassword !== "") {
      this.getDeliveryServiceList();
    }
  }

  getDeliveryServiceList = async () => {
    this.setState({
      loading: true,
    });

    try {
      const response = await axios.post("/api/integrations/auspost/delivery-services", {
        labelAccountNumber: this.props.labelAccountNumber,
        labelApiKey: this.props.labelApiKey,
        labelPassword: this.props.labelPassword,
      });

      if (response.data.status === "error") {
        this.setState({
          loading: false,
        });
        return message.error(
          "Error requesting delivery services from AusPost. Please try again.",
          5
        );
      }

      let deliveryServiceList = response.data.deliveryServiceList;

      return this.setState({
        deliveryServiceList: deliveryServiceList,
        loading: false,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        loading: false,
      });
      return message.error(
        "Error requesting delivery services from AusPost. Please try again.",
        5
      );
    }
  }

  reloadDeliveryServiceList = async () => {
    if (this.props.labelApiKey == null || this.props.labelApiKey === "" || this.props.labelPassword === "") {
      return message.error("Please add your Account number, API-Key and password first.", 4);
    }

    this.setState({
      loading: true,
    });

    // save entered API keys
    // await this.props.saveSettings();

    // get delivery service list
    await this.getDeliveryServiceList();
  };

  render() {
    const reloadDeliveryServiceListButtonDisabled =
      this.props.labelAccountNumber == null || this.props.labelApiKey === "" || this.props.labelPassword === ""
        ? true
        : false;
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>Account number</h4>
            <span>Enter your account number for eParcel and International products here.</span>
          </Col>
          <Col xs={24} md={10}>
            <Input
              value={this.props.labelAccountNumber}
              onChange={(e) =>
                this.props.handleChange('labelAccountNumber', e.target.value)
              }
              disabled={true}
              className="u-marginRight--none"
            />
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here. Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <a
              href="https://developers.auspost.com.au/apis/st-registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can request your Australia Post API-Key here
            </a>
            <br />
            {this.props.inputFieldValidation === false && (
              <p style={{ color: '#f00000' }}>
                API key cannot contain "API", "test", "sandbox" or special
                characters
              </p>
            )}
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange('labelApiKey', e.target.value)
              }
              disabled={true}
              value={this.props.labelApiKey}
              className="u-marginRight--none u-marginTop--sm"
            />
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>Password</h4>
            <span>Enter the password of your Australia Post API account here.</span>
          </Col>
          <Col xs={24} md={10}>
            <Input
              type="password"
              value={this.props.labelPassword}
              onChange={(e) =>
                this.props.handleChange('labelPassword', e.target.value)
              }
              disabled={true}
              className="u-marginRight--none"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>Automatically choose the cheapest rate</h4>
            <span>
              You can either let us pick the cheapest rate among all available
              rates for a shipment automatically <strong>or</strong> specify a
              default carrier and rate below.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <Checkbox
              checked={this.props.labelSelectCheapestRate}
              name="labelSelectCheapestRate"
              onChange={(e) =>
                this.props.handleChange(
                  "labelSelectCheapestRate",
                  e.target.checked
                )
              }
            >
              Enable
            </Checkbox>
          </Col>
        </Row>

        {
          this.props.labelSelectCheapestRate ? null : (
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={24} md={10}>
                  <h4>Delivery service</h4>
                  <span>Select a delivery service from Australia Post for your labels.</span>
                </Col>

                <Col xs={24} md={10}>
                  <Row type="flex" align="middle">
                    {reloadDeliveryServiceListButtonDisabled ? (
                      <Tooltip title="Enter account Number, API-Key and password and save at the bottom of the dialog first." className="u-width--100">
                        <Button
                          type="primary"
                          disabled={reloadDeliveryServiceListButtonDisabled}
                          style={{ width: '100%' }}
                        >
                          <Icon type="reload" /> Refresh delivery service list
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        type="primary"
                        onClick={this.reloadDeliveryServiceList.bind(this)}
                        loading={this.state.loading}
                        disabled={reloadDeliveryServiceListButtonDisabled}
                        style={{ width: '100%' }}
                      >
                        <Icon type="reload" /> Refresh delivery service list
                      </Button>
                    )}
                  </Row>
                  <br />
                  <Select
                    defaultValue={this.props.labelDeliveryService || undefined}
                    style={{ width: '100%' }}
                    onSelect={(value) => this.props.handleChange('labelDeliveryService', value)}
                    placeholder="Select your delivery service"
                    notFoundContent={
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">No Data</p>
                        </div>
                      </div>
                    }
                  >
                    {this.state.deliveryServiceList.map((option) => (
                      <Option value={option.product_id} key={option.product_id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}

export default AusPost;
