import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  Form,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

class GiftReturnsPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeTypeReturn = (type) => {
    return this.props.changeTypeReturn(type);
  };

  render() {
    return (
      <React.Fragment>
        <Button
          id="new-gift-return"
          style={{
            width: '100%',
            backgroundColor: this.props.brandColorButton,
            borderColor: this.props.brandColorButton,
          }}
          onClick={() => this.changeTypeReturn('giftReturnWithOrderInfo')}
        >
          <span
            style={{
              color: this.props.brandColorButtonText,
              fontStyle: this.props.brandFontStyle,
            }}
          >
            <FormattedMessage
              id="app.visitor.newReturn.buttonStartGiftReturnWithOrderInfo"
              description="Button to start a Gift Exchange"
              defaultMessage="Start a Gift Exchange with order info"
              values={{}}
            />
          </span>
        </Button>
        <Divider />
        <Button
          id="new-gift-return"
          style={{
            width: '100%',
            backgroundColor: this.props.brandColorButton,
            borderColor: this.props.brandColorButton,
          }}
          onClick={() => this.changeTypeReturn('giftReturnWithoutOrderInfo')}
        >
          <span
            style={{
              color: this.props.brandColorButtonText,
              fontStyle: this.props.brandFontStyle,
            }}
          >
            <FormattedMessage
              id="app.visitor.newReturn.buttonStartGiftReturnWithoutOrderInfo"
              description="Button to start a Gift Exchange"
              defaultMessage="Start a Gift Exchange without order info"
              values={{}}
            />
          </span>
        </Button>
      </React.Fragment>
    );
  }
}

export default GiftReturnsPicker;
