import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Icon,
  Checkbox,
  message,
  Tooltip
} from 'antd';
import VideoPopup from '../../../../components/VideoPopup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import axios from 'axios';

const { Option } = Select;

class Shippo extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      carrierListShippo: this.props.labelCarrier
        ? [
          {
            code: this.props.labelCarrier,
            name: this.props.labelCarrierName,
          },
        ]
        : [],
      ratesListShippo: this.props.labelServicelevel
        ? [
          {
            code: this.props.labelServicelevel,
            name: this.props.labelServicelevelName,
          },
        ]
        : [],
      carrierListLoaded: false,
      ratesListLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.labelApiKey) {
      this.reloadCarrierList();
    }
  }

  handleCarrierChange = async (value) => {
    try {
      this.reloadRatesList(value)

      const name = this.state.carrierListShippo.find(
        (carrier) => carrier.code === value
      ).name;

      this.props.handleChange('labelServicelevel', undefined);
      this.props.handleChange('labelServicelevelName', undefined);

      this.props.handleChange('labelCarrierName', name);
      return this.props.handleChange('labelCarrier', value);
    } catch (err) {
      return message.error(
        'Error requesting service-levels from Shippo. Please try again.',
        5
      );
    }
  };

  handleServicelevelChange = (value) => {
    const name = this.state.ratesListShippo.find(
      (rate) => rate.code === value
    ).name;
    this.props.handleChange('labelServicelevel', value);
    this.props.handleChange('labelServicelevelName', name);
  };

  reloadRatesList = async (value) => {
    const selectedCarrier = value;
    const response = await axios.post(
      `/api/integrations/shippo/servicelevels/${selectedCarrier}`,
      {
        labelApiKey: this.props.labelApiKey,
      }
    );
    if (response.data.status === 'error') {
      return message.error(
        'Error requesting service-levels from Shippo. Please try again.',
        5
      );
    }

    const ratesListShippo = response.data.ratesListShippo;

    this.setState({
      ratesListShippo,
      ratesListLoaded: true,
    });
  }

  reloadCarrierList = async () => {
    if (this.props.labelApiKey == null || this.props.labelApiKey === '') {
      return message.error('Please add your API key and API secret first.', 4);
    }

    this.setState({
      loading: true,
    });

    // await this.props.saveSettings();

    try {
      const response = await axios.post('/api/integrations/shippo/carriers', {
        labelApiKey: this.props.labelApiKey,
      });
      if (response.data.status === 'error') {
        this.setState({
          loading: false,
        });
        return message.error(
          'Error requesting carriers from Shippo. Please try again.',
          5
        );
      }

      const carrierListShippo = response.data.carrierListShippo;

      return this.setState({
        carrierListShippo,
        loading: false,
        ratesListShippo: [],
        carrierListLoaded: true,
        ratesListLoaded: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error(
        'Error requesting carriers from Shippo. Please try again.',
        5
      );
    }
  };

  handleServicelevelClick = () => {
    if (!this.state.ratesListLoaded && this.props.labelCarrier) {
      this.reloadRatesList(this.props.labelCarrier);
    }
  };

  handleCarrierSelectClick = () => {
    if (!this.state.carrierListLoaded) {
      this.reloadCarrierList();
    }
  };

  getOptionsFromList = (list) => {
    return list.map((item) => {
      const name = item.name;
      const code = item.code;
      return (
        <Option value={code} key={code}>
          {name}
        </Option>
      );
    });
  };

  render() {
    const reloadCarrierListButtonDisabled =
      this.props.labelApiKey === null || this.props.labelApiKey === '';

    const ratesList = this.getOptionsFromList(this.state.ratesListShippo);
    const carriersList = this.getOptionsFromList(this.state.carrierListShippo);

    return (
      <>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here (also called Token in some cases). Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col span={10}>
            <a
              href="https://app.goshippo.com/settings/api/"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find here in your Shippo account
            </a>
            <br />
            {this.props.inputFieldValidation === false && (
              <p style={{ color: '#f00000' }}>
                API key cannot contain "API", "test", "sandbox" or special
                characters
              </p>
            )}
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange('labelApiKey', e.target.value)
              }
              disabled={true}
              value={this.props.labelApiKey}
            />
          </Col>
        </Row>
        {this.context.featureAutoTracking && (
          <>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="dashboard-content"
            >
              <Col span={10}>
                <h4>Auto Tracking</h4>
                <span>
                  Automatically update the status of a return for both
                  in-transit ("Shipped") and delivered ("Received") events.
                  <br />
                  See our setup tutorial here:{' '}
                  <VideoPopup videoId="RPUPC_bNzqE" />
                  <br /> Copy the Link and add it{' '}
                  <a
                    href="https://app.goshippo.com/settings/api/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{' '}
                  in your Shippo account.
                </span>
              </Col>
              <Col span={10}>
                <Checkbox
                  checked={this.props.autoTracking}
                  name="autoTracking"
                  onChange={(e) =>
                    this.props.handleChange('autoTracking', e.target.checked)
                  }
                >
                  Enable
                </Checkbox>
                {this.props.autoTracking && (
                  <>
                    <CopyToClipboard
                      text={
                        'https://api.richcommerce.co/returns/webhook/' +
                        this.props.labelService +
                        '/' +
                        this.props.autoTrackingWebhookSecret
                      }
                    >
                      <Button type="primary">
                        <Icon type="copy" />
                        Copy Link
                      </Button>
                    </CopyToClipboard>{' '}
                    <span>
                      Watch Tutorial: <VideoPopup videoId="RPUPC_bNzqE" />
                    </span>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}

        <>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={10}>
              <h4>Scan-Based Labels (only pay when used)</h4>
              <span>
                Shippo supports Scan-Based labels only for national shipments
                with USPS, UPS and FedEx. If you are using Australia Post make
                sure to enable this option as well. More information{' '}
                <a
                  href="https://support.goshippo.com/hc/en-us/articles/201772795-How-do-I-create-return-labels-"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here from Shippo
                </a>
                .
              </span>
              <br />
              <br />
              <span>
                <strong>Important:</strong> when using multiple carriers (using
                our 'Choose cheapest rate') this option needs to be turned off
                unless all carriers support Scan-Based labels.
              </span>
            </Col>
            <Col span={10}>
              <Checkbox
                checked={this.props.shippoScanBasedLabels}
                onChange={(e) =>
                  this.props.handleChange(
                    'shippoScanBasedLabels',
                    e.target.checked
                  )
                }
              >
                Enable
              </Checkbox>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={10}>
              <h4>Automatically choose the cheapest rate</h4>
              <span>
                You can either let us pick the cheapest rate among all available
                rates for a shipment automatically <strong>or</strong> specify a
                default carrier and rate below.
              </span>
            </Col>
            <Col span={10}>
              <Checkbox
                checked={this.props.labelSelectCheapestRate}
                name="labelSelectCheapestRate"
                onChange={(e) =>
                  this.props.handleChange(
                    'labelSelectCheapestRate',
                    e.target.checked
                  )
                }
              >
                Enable
              </Checkbox>
            </Col>
          </Row>
        </>

        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>

        {!this.props.labelSelectCheapestRate ? (
          <>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}></Col>
              <Col span={10}>
                {reloadCarrierListButtonDisabled ? (
                  <Tooltip title="Enter API key and API secret and save at the bottom of the dialog first.">
                    <Button
                      type="primary"
                      disabled={reloadCarrierListButtonDisabled}
                    >
                      <Icon type="reload" /> Refresh carrier list
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="primary"
                    onClick={this.reloadCarrierList.bind(this)}
                    loading={this.state.loading}
                    disabled={reloadCarrierListButtonDisabled}
                  >
                    <Icon type="reload" /> Refresh carrier list
                  </Button>
                )}
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Carrier</h4>
                <p>Select a carrier from your account for your labels.</p>
              </Col>
              <Col span={10} onClick={this.handleCarrierSelectClick.bind(this)}>
                <Select
                  defaultValue={this.props.labelCarrier}
                  labelSelectCheapestRate
                  style={{ width: '66%' }}
                  onSelect={this.handleCarrierChange.bind(this)}
                  placeholder="Select your carrier contract"
                >
                  {carriersList}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Service</h4>
                <span>
                  Select a default rate. This rate will be used for all
                  prepaid-labels.
                </span>
              </Col>
              <Col span={10} onClick={this.handleServicelevelClick.bind(this)}>
                <Select
                  name="labelServicelevel"
                  style={{ width: '100%' }}
                  value={this.props.labelServicelevel ?? undefined}
                  onChange={this.handleServicelevelChange.bind(this)}
                  placeholder="Select the rate we should use for labels"
                >
                  {ratesList}
                </Select>
              </Col>
            </Row>
          </>
        ) : null}
      </>
    );
  }
}

export default Shippo;
