/**
 * Component for API settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tabs,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import WebhooksTable from './WebhooksTable';
const { TabPane } = Tabs;

class SettingsApi extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      keyName: undefined,
      apiKeys: undefined,
      token: undefined,
      visible: false,
      loading: true,
    };
  }

  async componentDidMount() {
    this.loadApiKeys();
  }

  async loadApiKeys() {
    try {
      const response = await axios.get('/api/token');
      if (response.data && response.data.status === 'error') {
        this.setState({ loading: false });
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }
      this.setState({
        loading: false,
        apiKeys: response.data,
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your API keys. Try again.', 5);
    }
  }

  handleSubmit = async () => {
    const { keyName } = this.state;
    const invalidKeyName = keyName == null || keyName === '';

    if (invalidKeyName) {
      return message.error('Please give the token a name to continue.', 5);
    }

    try {
      const response = await axios.post('/api/token', {
        keyName,
      });

      if (response.data.status === 'error') {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }

      const { token } = response.data;

      this.setState({
        keyName: undefined,
        token,
      });
      this.loadApiKeys();

      return message.success('API key created successfully.', 5);
    } catch (err) {
      return message.error('Error creating key. Please try again.', 5);
    }
  };

  deleteKey = async (id) => {
    try {
      const response = await axios.delete('/api/token', {
        data: {
          id,
        },
      });

      if (response.data && response.data.status === 'error') {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }

      this.loadApiKeys();

      return message.success('API Token deleted successfully.', 5);
    } catch (err) {
      return message.error('Error deleting API token. Please try again.', 5);
    }
  };

  updateKeyName = (e) => {
    const keyName = e.target.value;

    this.setState({
      keyName,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      keyName: undefined,
      token: undefined,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      keyName: undefined,
      token: undefined,
    });
  };

  render() {
    if (this.state.loading || this.context.featureApi === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Developers'}></PageButtonHeader>
        <div>
          <div>
            <div className="ContentPanel">
              {this.context.featureApi ? (
                <React.Fragment>
                  <Tabs
                    defaultActiveKey="1"
                    animated={false}
                    style={{ minHeight: 500 }}
                  >
                    <TabPane tab="Secret Keys" key="1">
                      {this.state.visible ? (
                        <Modal
                          title="Create a new API token?"
                          visible={this.state.visible}
                          onOk={this.handleSubmit.bind(this)}
                          okButtonProps={
                            this.state.token
                              ? { disabled: true }
                              : { disabled: false }
                          }
                          onCancel={this.handleCancel}
                          cancelText="Close"
                        >
                          {this.state.token ? (
                            <Alert
                              message="API Token created successfully"
                              description={
                                <React.Fragment>
                                  <p>
                                    Write the token down in a secure place. You
                                    will not be able to access it later.
                                  </p>
                                  <p>API Token: {this.state.token}</p>
                                </React.Fragment>
                              }
                              type="success"
                              showIcon
                            />
                          ) : (
                            <React.Fragment>
                              <h4>Token name</h4>
                              <Input
                                placeholder="Give the token a name"
                                defaultValue={this.state.keyName}
                                onChange={this.updateKeyName.bind(this)}
                              />
                            </React.Fragment>
                          )}
                        </Modal>
                      ) : null}

                      <Row
                        type="flex"
                        justify="center"
                        align="top"
                        className="u-marginTop--xs u-marginBottom--lg"
                      >
                        <Col span={24}>
                          <Alert
                            message="Looking for documentation?"
                            description={
                              <span>
                                We cover the API{' '}
                                <a
                                  href="https://support.richreturns.io/en/articles/9272307-introduction-to-our-api"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here in our Developer Docs
                                </a>
                              </span>
                            }
                            type="info"
                            showIcon
                          />
                        </Col>
                      </Row>

                      <div style={{ overflowX: 'auto' }}>
                        <ApiKeysTable
                          apiKeys={this.state.apiKeys}
                          deleteKey={this.deleteKey.bind(this)}
                        />
                      </div>
                      <Row
                        type="flex"
                        justify="end"
                        align="top"
                        style={{ marginTop: 30 }}
                      >
                        <Col>
                          {this.state.apiKeys &&
                          this.state.apiKeys.length > 2 ? (
                              <Button type="primary" disabled={true}>
                              Max. limit of keys reached
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                disabled={
                                  this.context.featureRulesQuota === 0
                                    ? true
                                    : false
                                }
                                onClick={this.showModal}
                              >
                              Create Secret Key
                              </Button>
                            )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Webhooks" key="2">
                      <Row
                        type="flex"
                        justify="center"
                        align="top"
                        className="u-marginTop--xs u-marginBottom--lg"
                      >
                        <Col span={24}>
                          <Alert
                            message="Looking for documentation?"
                            description={
                              <span>
                                We cover the API{' '}
                                <a
                                  href="https://support.richreturns.io/en/articles/9272307-introduction-to-our-api"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here in our Developer Docs
                                </a>
                              </span>
                            }
                            type="info"
                            showIcon
                          />
                        </Col>
                      </Row>
                      <div style={{ overflowX: 'auto' }}>
                        <WebhooksTable />
                      </div>
                    </TabPane>
                  </Tabs>
                  <Row
                    type="flex"
                    justify="center"
                    align="top"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Col>
                      <p>
                        Keep your Live API Token secure and confidential. Never
                        share or expose it publicly. For your safety, ensure
                        it's stored in a secure location.
                      </p>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <Upgrade
                  title="Take your platform to the next level"
                  description="Choose a Plan with API access (Plus & Custom Plans) to build comprehensive automated post-purchase processes."
                  devDocs={true}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ApiKeysTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: (text, record) => {
          return `name-${record.id}`;
        },
        width: '25%',
      },
      {
        title: 'Token',
        dataIndex: 'last4',
        key: (text, record) => {
          return `token-${record.id}`;
        },
        render: (text, record) => {
          return <span>****************{record.last4}</span>;
        },
        width: '25%',
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: (text, record) => {
          return `createdAt-${record.id}`;
        },
        render: (text, record) => {
          return moment(record.createdAt).format('MMM DD, YYYY');
        },
        width: '25%',
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          return `delete-${record.id}`;
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete this token?"
              onConfirm={() => this.props.deleteKey(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          );
        },
        width: '25%',
      },
    ];
  }

  render() {
    return (
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        className="dashboard-content"
      >
        <Col span={24}>
          <Table
            style={{ minWidth: '768px' }}
            columns={this.columns}
            dataSource={this.props.apiKeys}
            rowKey={(record) => record.last4}
            bordered={false}
            locale={{
              emptyText: (
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">No Data</p>
                  </div>
                </div>
              ),
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default SettingsApi;
