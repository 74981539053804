/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our  ShipStation
 * integrations for the user.
 *
 * @component
 */
import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Icon,
  Tooltip,
  message,
  Checkbox,
} from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";

const { Option } = Select;
class ShipStation extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ratesListShipStation: this.props.ratesListShipStation,
      carrierListShipStation: this.props.carrierListShipStation,
    };
  }

  componentDidMount() {
    if (this.props.labelApiKey && this.props.labelApiSecret) {
      this.reloadCarrierList();
    }
  };

  reloadCarrierList = async () => {
    if (this.props.labelApiKey == null || this.props.labelApiKey === "") {
      return message.error("Please add your API key and API secret first.", 4);
    }

    this.setState({
      loading: true,
    });

    // await this.props.saveSettings();

    try {
      const response = await axios.post(
        "/api/integrations/shipstation/carriers",
        {
          labelApiKey: this.props.labelApiKey,
          labelApiSecret: this.props.labelApiSecret,
          labelCarrier: this.props.labelCarrier,
        }
      );
      if (response.data.status === "error") {
        this.setState({
          loading: false,
        });
        return message.error(
          "Error requesting carriers from ShipStation. Please try again.",
          5
        );
      }

      const carrierListShipStation = response.data.carrierListShipStation;
      const ratesListShipStation = response.data.ratesListShipStation;

      return this.setState({
        carrierListShipStation,
        ratesListShipStation,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error(
        "Error requesting carriers from ShipStation. Please try again.",
        5
      );
    }
  };

  handleCarrierChange = async (value) => {
    try {
      const selectedCarrier = value;
      const response = await axios.post(
        "/api/integrations/shipstation/servicelevels",
        {
          labelApiKey: this.props.labelApiKey,
          labelApiSecret: this.props.labelApiSecret,
          selectedCarrier,
        }
      );
      if (response.data.status === "error") {
        return message.error(
          "Error requesting service-levels from ShipStation. Please try again.",
          5
        );
      }

      const ratesListShipStation = response.data.ratesListShipStation;
      this.setState({
        ratesListShipStation,
        labelCarrier: value,
      });
      return this.props.handleChange("labelCarrier", value)
    } catch (err) {
      return message.error(
        "Error requesting service-levels from ShipStation. Please try again.",
        5
      );
    }
  };

  render() {
    const reloadCarrierListButtonDisabled =
      this.props.labelApiKey == null ||
        this.props.labelApiKey === "" ||
        this.props.labelApiSecret == null ||
        this.props.labelApiSecret === ""
        ? true
        : false;
    let ratesList;
    if (this.state.ratesListShipStation) {
      ratesList = this.state.ratesListShipStation.map((rate) => {
        const rateName = rate.name;
        const rateCode = rate.code;
        return (
          <Option value={rateCode} key={rateCode}>
            {rateName}
          </Option>
        );
      });
    }
    let carriersList;
    if (this.state.carrierListShipStation) {
      carriersList = this.state.carrierListShipStation;
      const distinctCarriers = carriersList.filter((obj, index, self) =>
        index === self.findIndex((car) =>  car.code === obj.code)
      );
      carriersList = distinctCarriers.map((carrier) => {
        const carrierName = carrier.name;
        const carrierCode = carrier.code;
        return (
          <Option value={carrierCode} key={carrierCode}>
            {carrierName}
          </Option>
        );
      });
    }
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here (also called Token in some cases). Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col span={10}>
            <a
              href="https://ss.shipstation.com/#/settings/api"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find here in your Shipstation account
            </a>
            <br />
            {this.props.inputFieldValidation === false &&
              <p style={{ color: "#f00000" }}>API key cannot contain "API", "test", "sandbox" or special characters</p>
            }
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange("labelApiKey", e.target.value)
              }
              disabled={true}
              value={this.props.labelApiKey}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Secret</h4>
            <span>Enter your API-Secret here.</span>
          </Col>
          <Col span={10}>
            <Input
              // type = "password"
              value={this.props.labelApiSecret}
              onChange={(e) =>
                this.props.handleChange("labelApiSecret", e.target.value)
              }
              disabled={true}
            />
          </Col>
        </Row>

        {this.context.featureAutoTracking && (
          <>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="dashboard-content"
            >
              <Col span={10}>
                <h4>Auto Tracking</h4>
                <span>
                  Automatically update the status of a return for "Shipped" events.
                  <br />
                  {/*See our setup tutorial here:{' '}
                  <VideoPopup videoId="RPUPC_bNzqE" /> */}
                  <br /> Copy the Link and add it{' '}
                  <a
                    href="https://ship13.shipstation.com/settings/integrations/Webhooks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{' '}
                  in your Shipstation account. Be sure to set the type to "On Items Shipped".
                </span>
              </Col>
              <Col span={10}>
                <Checkbox
                  checked={this.props.autoTracking}
                  name="autoTracking"
                  onChange={(e) =>
                    this.props.handleChange('autoTracking', e.target.checked)
                  }
                >
                  Enable
                </Checkbox>
                {this.props.autoTracking && (
                  <>
                    <CopyToClipboard
                      text={
                        'https://api.richcommerce.co/returns/webhook/' +
                        this.props.labelService +
                        '/' +
                        this.props.autoTrackingWebhookSecret
                      }
                    >
                      <Button type="primary">
                        <Icon type="copy" />
                        Copy Link
                      </Button>
                    </CopyToClipboard>{' '}
                    <span>
                      {/* Watch Tutorial: <VideoPopup videoId="RPUPC_bNzqE" /> */}
                    </span>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}

        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}></Col>
          <Col span={10}>
            {reloadCarrierListButtonDisabled ? (
              <Tooltip title="Enter API key and API secret and save at the bottom of the dialog first.">
                <Button
                  type="primary"
                  disabled={reloadCarrierListButtonDisabled}
                >
                  <Icon type="reload" /> Refresh carrier list
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                onClick={this.reloadCarrierList.bind(this)}
                loading={this.state.loading}
                disabled={reloadCarrierListButtonDisabled}
              >
                <Icon type="reload" /> Refresh carrier list
              </Button>
            )}
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Carrier</h4>
            <p>Select a carrier from your account for your labels.</p>
          </Col>
          <Col span={10}>
            <Select
              defaultValue={this.props.labelCarrier}
              labelSelectCheapestRate
              style={{ width: "66%" }}
              onSelect={this.handleCarrierChange}
              placeholder="Select your carrier contract"
            >
              {carriersList}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Service</h4>
            <span>
              Select a default rate. This rate will be used for all
              prepaid-labels.
            </span>
          </Col>
          <Col span={10}>
            <Select
              name="labelServicelevel"
              defaultValue={this.props.labelServicelevel}
              style={{ width: "100%" }}
              onChange={(value) =>
                this.props.handleChange("labelServicelevel", value)
              }
              placeholder="Select the rate we should use for labels"
            >
              {ratesList}
            </Select>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ShipStation;
