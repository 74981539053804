/**
 * Component to detect the language.
 *
 * @component
 */

import axios from 'axios';
import React, { Component } from 'react';

// i18n
import { IntlProvider } from 'react-intl';
// setup i18n
import { TranslateContext } from '../contexts/TranslationContext';
import { getLocale, setLocale } from '../helpers/localePortal';
import messages_da from '../translations/da.json';
import messages_de from '../translations/de.json';
import messages_en from '../translations/en.json';
import messages_es from '../translations/es.json';
import messages_fi from '../translations/fi.json';
import messages_fr from '../translations/fr.json';
import messages_hi from '../translations/hi.json';
import messages_hr from '../translations/hr.json';
import messages_it from '../translations/it.json';
import messages_nl from '../translations/nl.json';
import messages_no from '../translations/no.json';
import messages_pl from '../translations/pl.json';
import messages_sl from '../translations/sl.json';
import messages_sv from '../translations/sv.json';
import messages_tr from '../translations/tr.json';

class ExternalPortalLanguage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: this.props.language,
      loading: false,
      messages: {
        de: messages_de,
        da: messages_da,
        en: messages_en,
        es: messages_es,
        fr: messages_fr,
        fi: messages_fi,
        hi: messages_hi,
        hr: messages_hr,
        it: messages_it,
        nl: messages_nl,
        no: messages_no,
        pl: messages_pl,
        sl: messages_sl,
        sv: messages_sv,
        tr: messages_tr,
      }
    };
  }
  static contextType = TranslateContext;

  getSettingLanguage = async (locale) => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const url = window.location.href;
      const urlObject = new URL(url);
      const companyIdentifier = urlObject.searchParams.get('c');
      const workspace = urlObject.searchParams.get('workspace');

      const language = locale || getLocale();
      this.setState({ language });
      try {
        const response = await axios.post('/api/visitors/settings/language', {
          companyIdentifier,
          workspace,
          language,
        });
        const errorStatus = response.data.status === 'error' ? true : false;
        if (errorStatus) {
          // TODO: error tracking
        }
        const {
          customTranslationsEnabled,
          customTranslations,
          enableTranslation,
        } = response.data;


        if (customTranslationsEnabled) {
          this.setState({ messages: { ...this.state.messages,  
            [language]: customTranslations } });
        }

        if (this.context.enableTranslation !== enableTranslation) {
          this.context.setEnableTranslation(enableTranslation);
        }

      } catch (err) {
        // TODO: error tracking
      }
      this.setState({ loading: false });
    }
  };

  componentDidMount = async () => {
    await this.getSettingLanguage();
    this.props.handleCloseLoader();
  };

  async componentDidUpdate(prevProps, prevState) {
    // Only call getSettingLanguage when language prop changes
    if (prevProps.language !== this.props.language) {
      if(this.props.language) {
        setLocale(this.props.language);
      }
      await this.getSettingLanguage(this.props.language);
    }

    // Update title if language or messages changed
    if (prevState.language !== this.state.language || prevState.messages !== this.state.messages) {
      const title = 'app.visitor.page.metaTitle';
      if (this.state.messages[this.state.language]?.[title]) {
        document.title = this.state.messages[this.state.language][title];
      } else {
        document.title = this.props.brandName ? 
          `${this.props.brandName} | Returns` : 
          'Returns';
      }
    }
  }

  render() {
    const language = this.state.language;

    return (
      <React.Fragment>
        <IntlProvider locale={language} messages={this.state.messages[language]}>
          {this.props.children}
        </IntlProvider>
      </React.Fragment>
    );
  }
}

export default ExternalPortalLanguage;
