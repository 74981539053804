import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  Form,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

class GiftReturnsWithoutOrderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      buttonLoading: false,
      orderSearchError: undefined,
    };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.props.onOk();
  };

  handleSave = () => {
    // this.props.onSave();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.handleSubmit(values);
      }
    });
  };

  async handleSubmit(values) {
    try {
      this.setState({
        buttonLoading: true,
        orderSearchError: undefined,
      });
      let {
        buyerName,
        productReturn,
        giftReturnFirstName,
        giftReturnLastName,
        giftReturnCompany,
        giftReturnResolution,
        giftReturnNote,
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      } = values;
      const payload = {
        companyIdentifier: this.props.companyIdentifier,
        typeReturn: 'giftReturn',
        buyerName,
        productReturn,
        giftReturnFirstName,
        giftReturnLastName,
        giftReturnCompany,
        giftReturnResolution,
        giftReturnNote,
        giftReturnEmail,
        giftReturnPhone,
        giftReturnAddress,
        giftReturnZipCode,
        giftReturnCity,
        giftReturnState,
        giftReturnStateCode,
        giftReturnCountry,
        giftReturnCountryCode,
      };
      await axios.post('/api/visitors/return/createGiftReturn', payload);
      this.setState({
        buttonLoading: false,
      });
      message.success('Gift return created successfully', 4);
      this.props.changeTypeReturn('status');
      // this.props.onCancel();
    } catch (err) {
      console.error(err);
      message.error(
        'Unable to search your order at the moment, Please confirm products are available in store.',
        4
      );
      this.setState({
        buttonLoading: false,
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <Form colon={false}>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.buyerName"
                description="Placeholder for order number input"
                defaultMessage="Byer's name"
              />
            }
          >
            {getFieldDecorator('buyerName', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.buyerName.warning"
                      description="Warning if user does not enter the order number"
                      defaultMessage="Please enter the byer's name"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.buyerNamePlaceholder',
                  description: 'Placeholder for order number input',
                  defaultMessage: "Byer's name",
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.productReturn"
                description="Product return"
                defaultMessage="Which product are you returning?"
              />
            }
          >
            {getFieldDecorator('productReturn', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.productReturn.warning"
                      description="Product return warning"
                      defaultMessage="Please enter your product you would like to return"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.productReturnPlaceholder',
                  description: 'Product return',
                  defaultMessage: 'Which product are you returning?',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnResolution"
                description="Product resolution"
                defaultMessage="Would you like to exchange for something else?"
              />
            }
          >
            {getFieldDecorator('giftReturnResolution', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnResolution.warning"
                      description="Resolution warning"
                      defaultMessage="Please enter your product you would like to return"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnResolutionPlaceholder',
                  description: 'Product resolution',
                  defaultMessage:
                    'Would you like to exchange for something else?',
                })}
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('giftReturnNote', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnNote.warning"
                      description="Note for Gift Exchange warning"
                      defaultMessage="Please enter any additional notes"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnNote',
                  description: 'Note for Gift Exchange"',
                  defaultMessage: 'Anything else we should know?',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnFistName"
                description="Label for customer name input"
                defaultMessage="Your first name"
              />
            }
          >
            {getFieldDecorator('giftReturnFirstName', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnFistName.warning"
                      description="Warning if user does not enter the name"
                      defaultMessage="Please enter your first name"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnFistNamePlaceholder',
                  description: 'Placeholder for customer name input',
                  defaultMessage: 'Your first name',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnLastName"
                description="Label for customer name input"
                defaultMessage="Your last name"
              />
            }
          >
            {getFieldDecorator('giftReturnLastName', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnLastName.warning"
                      description="Warning if user does not enter the name"
                      defaultMessage="Please enter your last name"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnLastNamePlaceholder',
                  description: 'Placeholder for customer name input',
                  defaultMessage: 'Your last name',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.giftReturnCompany"
                description="Label for customer's company input"
                defaultMessage="Your company"
              />
            }
          >
            {getFieldDecorator('giftReturnCompany', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.giftReturnCompany.warning"
                      description="Warning if user does not enter the company"
                      defaultMessage="Please enter your company"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.giftReturnCompanyPlaceholder',
                  description: "Placeholder for customer's company input",
                  defaultMessage: 'Your company',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.emailGift"
                description="Placeholder for email input"
                defaultMessage="Email"
              />
            }
          >
            {getFieldDecorator('giftReturnEmail', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.emailGift.warning"
                      description="Warning if user does not enter the email address"
                      defaultMessage="Please enter your email address"
                      values={{}}
                    />
                  ),
                },
                // { type: 'email', message: 'Please enter a valid email' },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.emailGiftPlaceholder',
                  description: 'Placeholder for email input',
                  defaultMessage: 'Email',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.phone"
                description="Placeholder for phone number input"
                defaultMessage="Phone"
              />
            }
          >
            {getFieldDecorator('giftReturnPhone', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.phone.warning"
                      description="Warning if user does not enter the phone number"
                      defaultMessage="Please enter your phone number"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.phonePlaceholder',
                  description: 'Placeholder for phone number input',
                  defaultMessage: 'Phone',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.address"
                description="Placeholder for address input"
                defaultMessage="Address"
              />
            }
          >
            {getFieldDecorator('giftReturnAddress', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.address.warning"
                      description="Warning if user does not enter the address"
                      defaultMessage="Please enter your address"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.addressPlaceholder',
                  description: 'Placeholder for address input',
                  defaultMessage: 'Address',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.zipCode"
                description="Placeholder for zip code input"
                defaultMessage="Zip Code"
              />
            }
          >
            {getFieldDecorator('giftReturnZipCode', {
              rules: [
                {
                  required: true,
                  message: {
                    message: (
                      <FormattedMessage
                        id="app.visitor.newReturn.zipCode.warning"
                        description="Warning if user does not enter the zip code"
                        defaultMessage="Please enter your zip code"
                        values={{}}
                      />
                    ),
                  },
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.zipCodePlaceholder',
                  description: 'Placeholder for zip code input',
                  defaultMessage: 'Zip Code',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.city"
                description="City for city input"
                defaultMessage="City"
              />
            }
          >
            {getFieldDecorator('giftReturnCity', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.city.warning"
                      description="Warning if user does not enter the city"
                      defaultMessage="Please enter your city"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.cityPlaceholder',
                  description: 'Placeholder for city input',
                  defaultMessage: 'City',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.state"
                description="Placeholder for state input"
                defaultMessage="State"
              />
            }
          >
            {getFieldDecorator('giftReturnState', {
              rules: [
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.state.warning"
                      description="Warning if user does not enter the state"
                      defaultMessage="Please enter your state"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.statePlaceholder',
                  description: 'Placeholder for state input',
                  defaultMessage: 'State',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.stateCode"
                description="Placeholder for state code input"
                defaultMessage="State Code"
              />
            }
          >
            {getFieldDecorator('giftReturnStateCode', {
              rules: [
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.stateCode.warning"
                      description="Warning if user does not enter the state code"
                      defaultMessage="Please enter your state code"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.stateCodePlaceholder',
                  description: 'Placeholder for state code input',
                  defaultMessage: 'State Code',
                })}
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.country"
                description="Placeholder for country input"
                defaultMessage="Country"
              />
            }
          >
            {getFieldDecorator('giftReturnCountry', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.country.warning"
                      description="Warning if user does not enter the country"
                      defaultMessage="Please enter your country"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.countryPlaceholder',
                  description: 'Placeholder for country input',
                  defaultMessage: 'Country',
                })}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <FormattedMessage
                id="app.visitor.newReturn.countryCode"
                description="Placeholder for country code input"
                defaultMessage="Country Code"
              />
            }
          >
            {getFieldDecorator('giftReturnCountryCode', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="app.visitor.newReturn.countryCode.warning"
                      description="Warning if user does not enter the country code"
                      defaultMessage="Please enter your country code"
                      values={{}}
                    />
                  ),
                },
              ],
            })(
              <Input
                placeholder={formatMessage({
                  id: 'app.visitor.newReturn.countryCodePlaceholder',
                  description: 'Placeholder for country code input',
                  defaultMessage: 'Country Code',
                })}
              />
            )}
          </Form.Item>
        </Form>
        <Row
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20px',
          }}
        >
          {/* <Button type="primary" onClick={this.handleSave}>
              <span style={{ width: 200 }}>Next</span>
            </Button> */}
          <Button
            id="new-gift-return"
            style={{
              width: '100%',
              backgroundColor: this.props.brandColorButton,
              borderColor: this.props.brandColorButton,
            }}
            onClick={this.handleSave}
          >
            <span
              style={{
                color: this.props.brandColorButtonText,
                fontStyle: this.props.brandFontStyle,
              }}
            >
              <FormattedMessage
                id="app.visitor.newReturn.buttonStartGiftReturn"
                description="Button to start a Gift Exchange"
                defaultMessage="Start a Gift Exchange"
                values={{}}
              />
            </span>
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

const WrappedGiftReturns = Form.create()(GiftReturnsWithoutOrderInfo);

export default WrappedGiftReturns;
