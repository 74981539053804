/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our  EasyPost
 * integrations for the user.
 *
 * @component
 */
import React, { Component } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Select,
  Divider,
  Input,
  Checkbox,
  InputNumber,
  Button,
  message
} from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
const { Option } = Select;

class CanadaPost extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      carrierListEasyPost: this.props.carrierListEasyPost,
      showBuyerAddressModal: false,
    };
  }

  selectService = (key, value) => {
    const labelServicelevelName = serviceLevel.find((rate) => rate.serviceCode === value).name;
    this.setState({
      labelServicelevel: value,
      labelServicelevelName: labelServicelevelName
    })
    this.props.handleChange(key, value);
    this.props.handleChange('labelServicelevelName', labelServicelevelName);
  }

  handleOnchangeKeyNumber = async (value) => {
    this.setState({ keyNumber: value });
  };

  generateApiKey = async () => {
    let keyNumber = this.state.keyNumber;
    if (!keyNumber || keyNumber === '') {
      return message.error('Please enter your username and password');
    };
    const credentials = keyNumber.replace(/\s/g, '');
    const bytes = new TextEncoder().encode(credentials);
    const base64Credentials = btoa(String.fromCharCode(...new Uint8Array(bytes)));
    this.props.handleChange('labelApiKey', base64Credentials);
  };

  render() {
    const ratesList = serviceLevel.map((rate) => {
      return (
        <Option value={rate.serviceCode} key={rate.serviceCode}>
          {rate.name}
        </Option>
      );
    });
    const currencies = currenciesOption.map((currency) => {
      return (
        <Option value={currency.currencyCode} key={currency.currencyCode}>
          {currency.name}
        </Option>
      );
    });
    const canadapostOptionsList = canadapostOptions.map((option) => {
      return (
        <Option value={option.code} key={option.code}>
          {option.name}
        </Option>
      );
    });
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle" style={{ paddingTop: "30px" }}>
          <Col span={10}>
            <h4>API Key</h4>
          </Col>
          <Col span={10}>
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange("labelApiKey", e.target.value)
              }
              disabled={true}
              value={this.props.labelApiKey}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Customer number</h4>
            <span>
              CanadaPost requires the customer number
            </span>
          </Col>
          <Col span={10}>
            {this.props.inputFieldValidation === false &&
              <p style={{ color: "#f00000" }}>Customer number </p>
            }
            <Input
              name="canadapostCustomerNumber"
              onChange={(e) =>
                this.props.handleChange("canadapostCustomerNumber", e.target.value)
              }
              disabled={true}
              value={this.props.canadapostCustomerNumber}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>

        {
          this.props.labelSelectCheapestRate ? null : (
            <React.Fragment>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10}>
                  <h4>Service</h4>
                  <span>
                    Select a default rate. This rate will be used for all
                    prepaid-labels.
                  </span>
                </Col>
                <Col span={10}>
                  <p>
                    Select your carrier on this page and copy the exact name of
                    your rate under 'Service Levels' to this field here.
                  </p>
                  <Select
                    name="labelServicelevel"
                    style={{ width: "100%" }}
                    value={this.state.labelServicelevel || this.props.labelServicelevel}
                    onChange={(value) =>
                      this.selectService("labelServicelevel", value)
                    }
                    placeholder="Select the rate we should use for labels"
                  >
                    {ratesList}
                  </Select>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
            </React.Fragment>
          )
        }

        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Canada Post Option</h4>
            <span>
              CanadaPost requires the 'Canada Post Option' if your return address is outside Canada.
            </span>
          </Col>
          <Col span={10}>
            <Select
              name="canadapostOption"
              style={{ width: "100%" }}
              value={this.state.canadapostOption || this.props.canadapostOption}
              onChange={(value) =>
                this.props.handleChange("canadapostOption", value)
              }
              placeholder="Select the option when create shipment"
            >
              {canadapostOptionsList}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Canada Post currency</h4>
            <span>
              CanadaPost requires the 'Canada Post Currency' if your return address is outside Canada.
            </span>
          </Col>
          <Col span={10}>
            <Select
              name="canadapostCurrency"
              style={{ width: "100%" }}
              value={this.state.canadapostCurrency || this.props.canadapostCurrency}
              onChange={(value) =>
                this.props.handleChange("canadapostCurrency", value)
              }
              placeholder="Select the rate we should use for labels"
            >
              {currencies}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Canada Post Conversion</h4>
            <span>
            The conversion rate from the Canadian dollar to the currency you entered in the currency element above; for example, if you used USD as the target currency and $1.00 CAD = $0.85 USD, the conversion rate is 0.85
            </span>
          </Col>
          <Col span={10}>
            <InputNumber
              min={0}
              name="canadapostConversionCAD"
              onChange={(value) =>
                this.props.handleChange("canadapostConversionCAD", value)
              }
              value={this.props.canadapostConversionCAD}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Automatically choose the cheapest rate</h4>
            <span>
              You can either let us pick the cheapest rate among all available
              rates for a shipment automatically <strong>or</strong> specify a
              default carrier and rate below.
            </span>
          </Col>
          <Col span={10}>
            <Checkbox
              checked={this.props.labelSelectCheapestRate}
              name="labelSelectCheapestRate"
              onChange={(e) =>
                this.props.handleChange(
                  "labelSelectCheapestRate",
                  e.target.checked
                )
              }
            >
              Enable
            </Checkbox>
          </Col>
        </Row>
      </React.Fragment >
    );
  }
}

const serviceLevel = [
  {
    serviceCode: 'DOM.RP',
    name: 'Regular Parcel'
  },
  {
    serviceCode: 'DOM.EP',
    name: 'Expedited Parcel'
  },
  {
    serviceCode: 'DOM.XP',
    name: 'Xpresspost'
  },
  {
    serviceCode: 'DOM.PC',
    name: 'Priority'
  },
  {
    serviceCode: 'DOM.LIB',
    name: 'Library Books'
  },
  {
    serviceCode: 'USA.EP',
    name: 'Expedited Parcel USA'
  },
  {
    serviceCode: 'USA.SP.AIR',
    name: 'Small Packet USA Air'
  },
  {
    serviceCode: 'USA.TP',
    name: 'Tracked Packet - USA'
  },
  {
    serviceCode: 'USA.TP.LVM',
    name: 'Tracked Packet - USA (LVM)'
  },
  {
    serviceCode: 'USA.XP',
    name: 'Xpresspost USA'
  },
  {
    serviceCode: 'INT.XP',
    name: 'Xpresspost International'
  },
  {
    serviceCode: 'INT.IP.AIR',
    name: 'International Parcel Air'
  },
  {
    serviceCode: 'INT.IP.SURF',
    name: 'International Parcel Surface'
  },
  {
    serviceCode: 'INT.SP.AIR',
    name: 'Small Packet International Air'
  },
  {
    serviceCode: 'INT.SP.SURF',
    name: 'Small Packet International Surface'
  },
  {
    serviceCode: 'INT.TP',
    name: 'Tracked Packet – International'
  },
];

const currenciesOption = [
  {
    currencyCode: 'CAD',
    name: 'Canadian Dollar'
  },
  {
    currencyCode: 'USD',
    name: 'USD'
  }
]

const canadapostOptions = [
  {
    name: 'SO - Signature',
    code: 'SO'
  },
  {
    name: 'COV - Coverage',
    code: 'COV'
  },
  {
    name: 'COD - Collect on delivery',
    code: 'COD'
  },
  {
    name: 'PA18 - Proof of Age Required - 18',
    code: 'PA18'
  },
  {
    name: 'PA19 - Proof of Age Required - 19',
    code: 'PA19'
  },
  {
    name: 'HFP - Card for pickup',
    code: 'HFP'
  },
  {
    name: 'DNS - Do not safe drop',
    code: 'DNS'
  },
  {
    name: 'LAD - Leave at door - do not card',
    code: 'LAD'
  },
  {
    name: 'D2PO - Deliver to Post Office',
    code: 'D2PO'
  },
  {
    name: 'RASE - Return at Sender’s Expense',
    code: 'RASE'
  },
  {
    name: 'RTS - Return to Sender',
    code: 'RTS'
  },
  {
    name: 'ABAN - Abandon',
    code: 'ABAN'
  },

];
export default CanadaPost;
