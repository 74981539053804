/**
 * Component for our Graph on a combination of
 * Refunds + Store Credit + Exchanges.
 *
 * @component
 */

import { Area } from '@ant-design/charts';
import {
  Col,
  DatePicker,
  Divider,
  message,
  Row,
  Spin,
  Typography
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import Upgrade from '../../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';

const { RangePicker } = DatePicker;

const { Text } = Typography;

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: undefined,
      data: [],
    };
  }

  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }

  async handleSubmit() {
    this.setState({
      loading: true,
    });

    if (!this.props.startDate || !this.props.endDate) {
      this.setState({
        loading: false,
      });
      return message.error('Select a start and end date.', 4);
    }
    try {
      const response = await axios.post(
        '/api/analytics/compare-overview-by-date?',
        {
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        }
      );

      if (response.data.error) {
        return message.error('Error fetching analytics data from server.', 4);
      }

      return this.setState({
        data: response.data.data,
        loading: false,
      });
    } catch (err) {
      message.error('Error fetching analytics data.', 4);
    }
  }
  onChange(dates, dateStrings) {
    // syntax: function(dates: [moment, moment], dateStrings: [string, string])
    this.props.updateDate(dateStrings);
    this.setState(
      {
        checkboxDisabled: false,
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  render() {
    const { date } = this.state;
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      const tooLate = date && current.diff(date, 'days') > 45;
      const tooEarly = date && date.diff(current, 'days') > 45;
      return tooEarly || tooLate;
    };
    return (
      <React.Fragment>
        {/*
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            
             <h1 className="TextHeading TextHeading--sm">
              <Tooltip title="Shows a comparison for the number of Exchange, Refund and Store-Credit items requested per day.">
                <Icon
                  style={{ fontSize: "16px", alignItems: "center" }}
                  type="info-circle"
                />
              </Tooltip>{" "}
              Overview
            </h1> 
          </Col>
        </Row>
        */}
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ rowGap: '10px' }}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 16 }}
              >
                <Text style={{ fontSize: 14 }}>
                  Shows a comparison for the number of Exchange, Refund and
                  Store-Credit items requested per day.
                </Text>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
              >
                <Spin spinning={this.state.loading}>
                  <Row type="flex" justify="center" align="middle">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment(this.props.startDate),
                        moment(this.props.endDate),
                      ]}
                      ranges={{
                        Today: [moment(), moment()],
                        'This Month': [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                        'Last Month': [
                          moment().subtract(1, 'month').startOf('month'),
                          moment().subtract(1, 'month').endOf('month'),
                        ],
                        'Year to Date': [moment().startOf('year'), moment()],
                        'Last Year': [
                          moment().subtract(1, 'year').startOf('year'),
                          moment().subtract(1, 'year').endOf('year'),
                        ],
                        "Year to Date": [moment().startOf('year'), moment()],
                        "Last Year": [moment().subtract(1, "year").startOf('year'), moment().subtract(1, "year").endOf('year')],
                      }}
                      onChange={this.onChange.bind(this)}
                      disabledDate={disabledDate}
                      onCalendarChange={(dateArray) => {
                        const endDateSelected = dateArray[1] != null;
                        if (endDateSelected) {
                          return this.setState({
                            date: undefined,
                          });
                        }

                        const startDateSelected = dateArray[0];
                        if (startDateSelected) {
                          this.setState({
                            date: dateArray[0],
                          });
                        }
                      }}
                    />
                  </Row>
                </Spin>
              </Col>
            </Row>

            {/* <Divider style={{ marginTop: 30, marginBottom: 30 }}>
              Select a start and end date
            </Divider> */}

            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 20 }}
            >
              <Col span={24}>
                <div style={{ overflowX: 'auto' }}>
                  <div style={{ minWidth: 768 }}>
                    <Area
                      data={this.state.data}
                      xField="date"
                      yField="volume"
                      smooth={true}
                      seriesField={'type'}
                      color={['#6897a7', '#8bc0d6', '#60d7a7', '#0000FF']}
                      xAxis={{
                        type: 'time',
                        mask: 'YYYY-MM-DD',
                        tickCount: 5,
                      }}
                      yAxis={{
                        label: {
                          formatter: function formatter(v) {
                            return ''
                              .concat(v)
                              .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                                return ''.concat(s, ',');
                              });
                          },
                        },
                        tickInterval: 1,
                      }}
                      legend={{ position: 'top' }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          this.context.featureAnalyticsAdvanced !== undefined && (
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={24}>
                  <Divider />
                </Col>
                <Upgrade
                  title="Build advanced rules & scenarios for your returns."
                  description="Upgrade to a higher plan to tailor returns to your business processes and goals based on order data and customer actions."
                  faqLink="https://support.richreturns.io/en/articles/9272498-overview-of-advanced-rules"
                  videoId="DwAxUUS7A0o"
                />
              </Row>
            </React.Fragment>)
        )}
      </React.Fragment>
    );
  }
}

export default Overview;
