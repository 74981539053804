import { FullStory, init } from '@fullstory/browser';

export const initFullStory = (params) => {
  const {
    domain,
    displayName,
    email,
    status_str,// 'active' or 'uninstalled'
    shopId_str, // unique identifier of shop (ie 'xxxxx' in xxxxx.myshopify.com or xxxxx.mybigcommerce.com, unique URL)
    platform_str,// platform name (ie 'shopify', 'bigcommerce')
    shopify_planName,// platform plan name (ie 'shopify_plus')
    appHubId_str,// business unit name (ie 'conversionbear')
    appName_str,// app name (ie 'checkoutbear')
    plan_types,
    country,
    days_active,
    return_number,
    trial_period,
    trial_days,
    add_ons,
    number_of_rules,
    subscription_interval_time,
  } = params;
  // If having auth0Token => access via app simulator

  init({ orgId: 'o-1GYZWT-na1' })

  if (domain) {
    FullStory('setIdentity', {
      uid: domain,
      properties: {
        displayName,
        email,
        status_str,
        shopId_str,
        platform_str,
        shopify_planName,
        appHubId_str,
        appName_str,
        plan_types,
        country,
        days_active,
        return_number,
        trial_period,
        trial_days,
        add_ons,
        number_of_rules,
        subscription_interval_time
      }
    });
  }
};

export const trackFSEvent = (name, properties) => {
  // console.log('trackFSEvent: ', name, properties);
  FullStory('trackEvent', { name, properties });
};