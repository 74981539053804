/**
 * Component to register Australia Post
 *
 * @component
 */

import React from 'react';
import { Modal, Input, message, Row, Col, Divider, Button } from 'antd';
import axios from 'axios';
// import { trackFSEvent } from '../helpers/fullstory';

const LABEL_SERVICE = 'auspost';
class AuspostModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      active: false,
      labelAccountNumber: null,
      labelApiKey: null,
      labelPassword: null,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`/api/carrierSettings/${LABEL_SERVICE}`);
      if (response.data) {
        const { carrier } = response.data;
        this.setState({
          id: carrier.id,
          active: carrier.active,
          labelApiKey: carrier.labelApiKey,
          labelPassword: carrier.labelPassword,
          labelAccountNumber: carrier.labelAccountNumber,
        });
      }
    } catch (err) {
      if (err.response.status !== 404) {
        console.log(err);
        message.error('something went wrong please try again', 5);
      }
    }
  }

  validateFields = () => {
    const { labelApiKey, labelAccountNumber, labelPassword } = this.state;
    if (!labelApiKey || labelApiKey === '') {
      message.error('API Key is required.');
      return false;
    }
    if (!labelAccountNumber || labelAccountNumber === '') {
      message.error('Account Number is required.');
      return false;
    }
    if (!labelPassword || labelPassword === '') {
      message.error('Password is required.');
      return false;
    }
    return true;
  };

  getPayload = () => {
    const { id ,active, labelApiKey, labelAccountNumber, labelPassword } = this.state;
    return {
      id,
      active,
      labelApiKey,
      labelAccountNumber,
      labelPassword,
      labelService: LABEL_SERVICE,
    };
  };

  handleOk = async () => {
    this.setState({
      loading: true,
    });
    if (!this.validateFields()) {
      this.setState({
        loading: false,
      });
      return;
    }
    const payload = this.getPayload();

    try {
      const response = await axios.post('/api/carrierSettings/update', payload);
      console.log(response);
      message.success(
        'Australia Post integration has been successfully updated.',
        5
      );
      this.props.setModalVisible(null);
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error('something went wrong please try again', 5);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Modal
        title="Set up your Australia Post integration"
        visible={true}
        width="50%"
        onOk={this.handleOk}
        confirmLoading={this.state.confirmLoading}
        onCancel={() => this.props.setModalVisible(null)}
      >
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>Account number</h4>
            <span>
              Enter your account number for eParcel and International products
              here.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <Input
              value={this.state.labelAccountNumber}
              onChange={(e) => {
                this.handleChange('labelAccountNumber', e.target.value);
              }}
              className="u-marginRight--none"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here. Click on the link at the top to see where
              to find it.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <a
              href="https://developers.auspost.com.au/apis/st-registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can request your Australia Post API-Key here
            </a>
            <br />
            {/* {this.props.inputFieldValidation === false && (
              <p style={{ color: '#f00000' }}>
                API key cannot contain "API", "test", "sandbox" or special
                characters
              </p>
            )} */}
            <Input
              name="labelApiKey"
              onChange={(e) => {
                this.handleChange('labelApiKey', e.target.value);
              }}
              value={this.state.labelApiKey}
              className="u-marginRight--none u-marginTop--sm"
            />
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={24} md={10}>
            <h4>Password</h4>
            <span>
              Enter the password of your Australia Post API account here.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <Input
              type="password"
              value={this.state.labelPassword}
              onChange={(e) => {
                this.handleChange('labelPassword', e.target.value);
              }}
              className="u-marginRight--none"
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default AuspostModal;
