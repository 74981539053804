/**
 * Component to update Return resolutions on Merchant Dashboard
 * Merchants can update the resolution of a product from their dashboard
 * 
 * @component
 */

import React, { Component } from 'react'
import {
  Button,
  Modal,
  Select,
  Divider,
  Row,
  Col,
  message,
  Form,
  List,
  Badge,
  Spin,
} from 'antd'
import axios from 'axios'
import { placeholderImageUrl } from '../../../../constants/constants'
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext'
import StoreWideProducts from '../../../portal/storeWideExchanges/StoreWideProducts'
import ShowProductDetails from "../../../portal/advancedExchanges/ShowProductDetails"
import { IconTray } from '../../../../components/CustomReturnsIcons';
import '../../../../styles/App.css'
const { Option } = Select
const FormItem = Form.Item

class UpdateResolution extends Component {
  static contextType = SubscriptionContext
  constructor(props) {
    super(props)
    this.state = {
      loadingResolution: true,
      loadingExchangeOptions: false,
      exchangeOptionsTitle: undefined,
      loading: false,
      showStoreWideProducts: false,
      showAdvanceExchnageProducts: false,
      returnSettings: {},
      advancedExchanges: {},
      advanceExchangeProducts: [],
      hideProducts: false,
      loadingUpdate: false,
      exchangeItemSelected: false,
      resolutionGiftReturn: props?.resolutionGiftReturn || false,
    }
  }

  exchangeSelected = async (product,resolution) => {
    const companyIdentifier = this.context.identifier
    const returnNumber = this.props.returnObject.returnNumber
    const lineItemId = product.lineItemId
    const lineItemReturnQuantity = product.quantity

    try {
      const response = await axios.post('/api/returns/getExchangeProducts', {
        companyIdentifier,
        returnNumber,
        lineItemId,
        lineItemReturnQuantity,
        resolution,
      })
      if (response.data.status === 'error') {
        this.setState({
          loadingExchangeOptions: false,
        })
        return message.error(
          'There was an error loading your exchange options. Try again',
          5
        )
      }
      this.setState({
        loading: false,
        loadingExchangeOptions: false,
      })
      return response.data
    } catch (err) {
      this.setState({
        loadingExchangeOptions: false,
      })
      return message.error(
        'There was an error loading your exchange options. Try again',5)
    }
  }

  handleResolutionUpdate = async (resolution, product) => {
    if (resolution === 'refundToExchange') {
      this.setState({
        exchangeItemSelected: false,
        loadingExchangeOptions: true,
        showStoreWideProducts: false,
        showAdvanceExchnageProducts: false,
        showStoreWideProducts: false,
      })
      const response = await this.exchangeSelected(product,resolution)
      const {
        exchangeOptionsAvailable,
        exchangeOptionsTitle,
        exchangeOptions,
      } = response
      if (exchangeOptionsAvailable === false) {
        return message.error("No exchange options ")
      } else {
        this.setState({
          [`exchangeOptionsTitle_${product.lineItemId}`]: exchangeOptionsTitle,
          [`exchangeOptions_${product.lineItemId}`]: exchangeOptions,
          loading: false,
        })
      }
    } else if (resolution === 'storeWideExchange') {
      this.setState({
        exchangeItemSelected: false,
        showStoreWideProducts: true,
        [`exchangeOptionsTitle_${product.lineItemId}`]: "",
        [`exchangeOptions_${product.lineItemId}`]: [],
        showAdvanceExchnageProducts: false,
      })
    } else if (resolution === "advancedExchange"){
      this.setState({
        loading:true,
        hideProducts: false,
        showAdvanceExchnageProducts: true,
        showStoreWideProducts: false,
        exchangeItemSelected: false,
        [`exchangeOptionsTitle_${product.lineItemId}`]: "",
        [`exchangeOptions_${product.lineItemId}`]: [],
      })
      const data = await this.exchangeSelected(product,resolution)
      const advanceExchangeProducts = Array.isArray(data.products) ? data.products : []
      this.setState({
        advanceExchangeProducts,
      })
    } else {
      this.setState({
        showStoreWideProducts: false,
        exchangeItemSelected: true,
        [`exchangeOptionsTitle_${product.lineItemId}`]: "",
        [`exchangeOptions_${product.lineItemId}`]: [],
      })
    }
  }

  getResoulutions = (returnSettings) => {
    let resolutionOptions = {
      refundToExchange: 'Exchange',
      refundToCredit: 'Store Credit',
    }

    if (!this.props.resolutionGiftReturn) {
      resolutionOptions = {
        ...resolutionOptions,
        refundToOriginal: 'Refund',
      }
      for (let i = 1; i <= 3; i++) {
        const customKey = `refundToCustom${i}`
        const customLabelKey = `refundToCustom${i}Label`
        if (returnSettings[customKey]) {
          resolutionOptions = {
            ...resolutionOptions,
            [customKey]: returnSettings[customLabelKey],
          }
        }
      }
    }

    if (this.context.featureStoreWideExchange && returnSettings.returnOptionStoreWideExchange) {
      resolutionOptions = {
        ...resolutionOptions,
        storeWideExchange: 'Store-Wide Exchange',
      }
    }
    if (this.context.featureAdvancedExchanges) {
      resolutionOptions = {
        ...resolutionOptions,
        advancedExchange: 'Advanced Exchange',
      }
    }
    const options = Object.entries(resolutionOptions).map(([key, value]) => (
      <Option key={key} value={key}>
        {value}
      </Option>
    ))
    return options
  }

  componentDidMount = async () => {
    try {
      const response = await axios.get('/api/returnSettings')
      if (response.data == null) {
        return
      }
      const resolutionOptions = this.getResoulutions(response.data)
      this.setState({
        updateReturnResolution: response.data.updateReturnResolution,
        resolutionOptions,
        returnSettings: response.data,
        loadingResolution: false,
      })
    } catch (err) {
      message.error(`Error getting Return Settings from the server`);
      this.setState({
        loadingResolution: false,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loadingUpdate: true,
    })
    this.props.form.validateFields(async (err, values) => {
      values.returnId = this.props.returnObject.id;
      values.productId = this.props.item.id;
      values.companyIdentifier = this.context.identifier;
      let { advancedExchanges } = this.state;
      if (err) {
        return message.error(
          'Something is wrong with the provided values, Please try again',
          3
        )
      }
      try {
        if (this.props.resolutionGiftReturn) {
          this.props.updateProductRequest({
            values,
            advancedExchanges,
          });
        } else {
          const response = await axios.post('/api/returns/updateResolution',{values, advancedExchanges})
  
          if (response.data == null) {
            return
          }
          message.success('Resolution updated successfully', 3)
          this.setState({
            loadingUpdate: false,
          })
          this.props.handleGetReturn();
        }
        this.props.handleClose();
      } catch (err) {
        this.setState({
          loadingUpdate: false,
        })
        console.log(err, 'Error while updating return resolution')
        message.error("Error while updating return resolution, Please try again",3)
      }
    })
  }

  updateExchangedProduct = (productInfo) => {
    const {
      lineItemIdToBeExchanged,
      productId,
      productName,
      variantId,
    } = productInfo;
    const advancedExchanges = {}

    advancedExchanges[`products_${lineItemIdToBeExchanged}`] = [productInfo];
    advancedExchanges[`exchangedItemProductName_${lineItemIdToBeExchanged}`] = productName;
    advancedExchanges[`exchangedItemProductId_${lineItemIdToBeExchanged}`] = productId;
    advancedExchanges[`exchangedVariantId_${lineItemIdToBeExchanged}`] = variantId;

    this.setState({
      [`advancedExchangeSelected_${lineItemIdToBeExchanged}`]: true,
      advancedExchanges,
      advancedExchangeLineItemId: null,
      exchangeItemSelected: true,
    });
  };

  hideProducts = () => {
    this.setState({
      showStoreWideProducts:false,
    })
  }

  handleSelectedProduct = (product) => {
    const variant = product.selectedVariant;
    const { lineItemId } =  this.props.item;
    this.updateExchangedProduct({
      exchangeItemSelected: true,
      lineItemIdToBeExchanged: lineItemId,
      productId: product.id,
      productName: product.title,
      variantId: variant.id,
      variantTitle: variant.title,
    })
  }

  clearAdvancedExchange(lineItemIdToBeExchanged) {
    if (lineItemIdToBeExchanged) 
    {
      this.props.form.setFieldsValue({
        [`resolution_${lineItemIdToBeExchanged}`]: undefined,
      })
    }
    const { advancedExchanges } = this.state;
    
    advancedExchanges[
      `products_${lineItemIdToBeExchanged}`
    ] = [];
    
    advancedExchanges[
      `exchangedItemProductName_${lineItemIdToBeExchanged}`
    ] = undefined;
    advancedExchanges[
      `exchangedItemProductId_${lineItemIdToBeExchanged}`
    ] = undefined;
    advancedExchanges[
      `exchangedVariantId_${lineItemIdToBeExchanged}`
    ] = undefined;

    this.setState({
      [`advancedExchangeSelected_${lineItemIdToBeExchanged}`]: true,
      advancedExchanges,
    });
  }

  showProductDetails = async (showProductDetails = true, productId) => {
    this.setState({
      showProductDetails: showProductDetails,
      selectedProductId: productId,
      drawerTitle: showProductDetails ? 'Product Details' : 'Select new Item',
      hideProducts: productId ? false : true,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <React.Fragment>
        <Modal
          className="removeFormMargin"
          width={730}
          title="Update Resolution"
          visible={this.props.visible}
          onCancel={this.props.handleClose}
          footer={[
            <Button key="back" onClick={this.props.handleClose}>
              Cancel
            </Button>,
            <Button
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={this.handleSubmit}
              loading={this.state.loadingUpdate}
              disabled={!this.state.exchangeItemSelected}
            >
              Update
            </Button>,
          ]}
        >
          <Form>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={12}>
                <h4>Choose New Resolution</h4>
              </Col>
              <Col span={12} className="resolutionOptions">
                <FormItem>
                  {getFieldDecorator('updatedResolution', {
                    initialValue: this.props.item.resolutionLabel
                      ? this.props.item.resolutionLabel === "Store credit" ? "Store Credit" : this.props.item.resolutionLabel
                      : this.props.returnObject.resolution &&
                        this.props.returnObject.resolution.includes(
                          'refundToCustom'
                        )
                        ? this.props.returnObject.resolutionLabel
                        : this.props.resolutionOptions[
                          this.props.returnObject.resolution
                        ],
                    rules: [
                      {
                        required: true,
                        message:
                          'Please indicate the details of your exchange.',
                      },
                    ],
                  })(
                    <Select
                      onChange={(value) =>
                        this.handleResolutionUpdate(value, this.props.item)
                      }
                      disabled={this.state.loadingResolution}
                      notFoundContent={
                        <div className="flex-row flex-middle-xxs flex-center-xxs">
                          <div className="flex-col-xxs-12">
                            <IconTray size="md" />
                          </div>
                          <div>
                            <p className="TextBody TextBody--xxxs">No Data</p>
                          </div>
                        </div>
                      }
                    >
                      {this.state.resolutionOptions?.map(
                        (resolutionOption) => resolutionOption
                      )}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            {this.state.loadingExchangeOptions ? (
              <Row type='flex' justify='space-around' align='middle'>
                <Spin
                  spinning={this.state.loadingExchangeOptions}
                  style={{ paddingTop: '45px' }}
                ></Spin>
              </Row>
            ) : 
              <React.Fragment>
                {this.state[`exchangeOptions_${this.props.item.lineItemId}`] &&
                  this.state[`exchangeOptions_${this.props.item.lineItemId}`].length >
                  0 ? (
                    <React.Fragment>
                      <Divider />
                      <Row type="flex" justify="space-around" align="middle">
                        <Col span={12}>
                          <h4>Choose new Variant</h4>
                        </Col>
                        <Col span={12} className="resolutionOptions">
                          <FormItem>
                            {getFieldDecorator(
                              `exchangeItem_${this.props.item.lineItemId}`,
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please select an exchange item.',
                                  },
                                ],
                              }
                            )(
                              <Select 
                                placeholder={'Select new Variant'} 
                                onChange={() => this.setState({exchangeItemSelected: true})}
                                notFoundContent={
                                  <div className="flex-row flex-middle-xxs flex-center-xxs">
                                    <div className="flex-col-xxs-12">
                                      <IconTray size="md" />
                                    </div>
                                    <div>
                                      <p className="TextBody TextBody--xxxs">No Data</p>
                                    </div>
                                  </div>
                                }
                              >
                                {this.state[
                                  `exchangeOptions_${this.props.item.lineItemId}`
                                ].map((option) => {
                                  if (
                                    !this.state.exchangeSameVariant &&
                                    (option.title === this.props.item.variant_title ||
                                      option.exchangeVariantId ===
                                      this.props.item.variant_Id)
                                  ) {
                                    return null;
                                  } else {
                                    return this.state[
                                      `exchangeOptions_${this.props.item.lineItemId}`
                                    ].length === 1 ? (
                                        <Option
                                          value={option.exchangeVariantId}
                                          key={option.exchangeVariantId}
                                          disabled={option.disabled}
                                          style={{
                                            color:
                                              option.disabled && 'rgba(0, 0, 0, 0.25)',
                                          }}
                                        >
                                          {option.disabledReason
                                            ? `${this.props.item.title} (${option.disabledReason})`
                                            : this.props.item.title}
                                        </Option>
                                      ) : (
                                        <Option
                                          value={option.exchangeVariantId}
                                          key={option.exchangeVariantId}
                                          disabled={option.disabled}
                                          style={{color: option.disabled && 'rgba(0, 0, 0, 0.25)'}}
                                        >
                                          {option.disabledReason
                                            ? `${option.title} (${option.disabledReason})`
                                            : option.title}
                                        </Option>
                                      )
                                  }
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) : null
                }
              </React.Fragment>
            }
            {this.state.showStoreWideProducts &&
              <React.Fragment>
                <Divider />
                <StoreWideProducts
                  handleSelectedProduct={this.handleSelectedProduct}
                  companyIdentifier={this.context.identifier}
                  currentProduct={this.props.item}
                  returnSettings={this.state.returnSettings}
                  currency={this.props.returnObject.currency}
                  isMerchantDashboard={true}
                  hideProducts={this.hideProducts}
                />
              </React.Fragment>
            }
            {this.state.advancedExchanges[
              `products_${this.props.item.lineItemId}`
            ]&&this.state.advancedExchanges[
              `products_${this.props.item.lineItemId}`
            ].length > 0 ? (
                <Row type="flex" justify="center" align="top">
                  <Divider/>
                  <Col span={12} style={{marginTop:25}}>
                    <h4>Selected Exchange Item</h4>
                  </Col>
                  <Col span={12}>
                    <List
                      style={{ "borderRadius": 4, "background": "#fbfbfd" }}
                      bordered={true}
                      itemLayout="horizontal"
                      dataSource={this.state.advancedExchanges[`products_${this.props.item.lineItemId}`]}
                      renderItem={(item) => (
                        <List.Item >
                          <Button
                            style={{
                              position: 'absolute',
                              background: 'white',
                              color: 'black',
                              padding: '0 7px',
                              borderRadius: '20px',
                              top: '-10px',
                              right: '-10px',
                            }}
                            onClick={() => this.clearAdvancedExchange(item.lineItemIdToBeExchanged)}
                          >
                          x
                          </Button>
                          <List.Item.Meta
                            className="exchangeItem"
                            title={
                              <Badge
                                count={"Exchange Item"}
                                style={{ backgroundColor: '#52c41a'}}
                              />
                            }
                            description={item.productName + ' - ' + item.variantTitle}
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              ) : null}
            {this.state.showAdvanceExchnageProducts && 
              <React.Fragment>
                {!this.state.hideProducts && <Divider/>}
                {this.state.loading ? (
                  <Row type='flex' justify='space-around' align='middle'>
                    <Spin
                      spinning={this.state.loading}
                      style={{ paddingTop: '45px' }}
                    ></Spin>
                  </Row>
                ) : (
                  <div className='productsDrawer'>
                    {(!this.state.showProductDetails && !this.state.hideProducts) && (
                      <React.Fragment>
                        <List
                          itemLayout='horizontal'
                          dataSource={this.state.advanceExchangeProducts}
                          locale={{ emptyText: 'No products found' }}
                          renderItem={(item) => (
                            <List.Item>
                              <List.Item.Meta
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.showProductDetails(true, item.id)}
                                avatar={
                                  <img width='100px' height='100px' src={item?.image?.src ? item.image.src : placeholderImageUrl} />
                                }
                                title={<a>{item.title}</a>}
                              />
                            </List.Item>
                          )}
                        />
                      </React.Fragment>
                    )}
                    {this.state.showProductDetails && (
                      <ShowProductDetails
                        {...this.props}
                        isMerchantDashboard={true}
                        lineItemIdToBeExchanged={this.props.item.lineItemId}
                        productId={this.state.selectedProductId}
                        companyIdentifier={this.context.identifier}
                        returnNumber={this.props.returnObject.returnNumber}
                        showProductDetails={this.showProductDetails}
                        updateExchangedProduct={this.updateExchangedProduct}
                        currentProduct={this.props.item}
                      />
                    )}
                  </div>
                )}
              </React.Fragment>
            }
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}
const WrappedChangeResolution = Form.create()(UpdateResolution)
export default WrappedChangeResolution
